import { useEffect } from 'react'
import { DataTable } from '../../../../../../components/DataTable'
import * as Styled from './styles'
import fetchAccount from '../../../../../../services/account'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Pagination } from '@mantine/core'
import { ChevronDown, Search } from 'lucide-react'
import debounce from '../../../../../../utils/debounce'
import { toast } from 'react-toastify'
import fetchCarCategory from '../../../../../../services/carCategory'
import { ClipLoader } from 'react-spinners'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useTranslation } from 'react-i18next'

export const Drivers = ({ driver, id }) => {
  const [data, setData] = useState()
  const [total, setTotal] = useState()
  const [search, setSearch] = useState({
    name: '',
    orderBy: 'name',
    size: 10,
    page: 0,
    direction: 'asc'
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [refreshModal, setRefreshModal] = useState(false)
  const [selection, setSelection] = useState(['1'])
  const [categories, setCategories] = useState()
  const [loading, setLoading] = useState(true)

  const { t } = useTranslation()

  const navigate = useNavigate()
  const { user } = useAuth()

  const handleChange = (value, name) => {
    if (name === 'size' || name === 'page') {
      setSearch((prevSearch) => ({ ...prevSearch, [name]: value }))
    } else {
      debounce(() => setSearch({ ...search, [name]: value }), 500)
    }
  }

  useEffect(() => {
    const params = Object.entries(search).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key] = value
      }
      return acc
    }, {})
    params.role = 'COMPANY_USER'
    params.companyId = id

    fetchAccount
      .getAllAccount(params)
      .then((res) => {
        if (res) {
          setData(res.data.content)
          const totalPages = Math.ceil(res.data.totalElements / search.size)
          setTotal(totalPages)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading(false)
      })
  }, [search, refreshModal])

  useEffect(() => {
    fetchCarCategory.getAll({ deleted: false }).then((res) => {
      setCategories(res?.data?.content)
    })
  }, [])

  const handleDeleteAccount = () => {
    fetchAccount
      .delete(deleteModal.id)
      .then(() => {
        toast.success(t('toast.successDeleteAccount'))
        setDeleteModal(false)
        setRefreshModal(!refreshModal)
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(t('toast.errorDeleteAccount'))
      })
  }

  const handleRegisterForm = (data) => {
    if (data === 'autonomo') {
      navigate('driver/registrar')
    } else {
      navigate('empresa/registrar')
    }
  }

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.FiltersWrapper>
          <Input.Wrapper id="filters">
            <Input
              id="input-demo"
              placeholder="Insira o nome"
              size={'lg'}
              rightSection={<Search width={24} height={24} color="#BFBFBF" />}
              onChange={(e) => handleChange(e.target.value, 'name')}
            />
          </Input.Wrapper>
          <Input
            id="select-filter"
            component="select"
            rightSection={<ChevronDown />}
            pointer
            mt="md"
            onChange={(e) =>
              setSearch({
                ...search,
                deleted: e.target.value
              })
            }
          >
            <option value="">Status</option>
            <option value="false">Ativo</option>
            <option value="true">Inativo</option>
          </Input>
          <Input
            id="select-filter"
            component="select"
            rightSection={<ChevronDown />}
            pointer
            mt="md"
            onChange={(e) =>
              setSearch({
                ...search,
                vehicleCategoryName: e.target.value
              })
            }
          >
            <option value="">Categoria</option>
            {categories?.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Input>
        </Styled.FiltersWrapper>
        <Styled.ConfirmButton
          onClick={() => {
            if (user?.hostel?.id) {
              navigate(`/dashboard/partnership/${user.hostel.id}/account`)
            } else if (user?.company?.id) {
              if (driver) {
                navigate(
                  `/dashboard/frota/empresa/${user.company.id}/motorista`
                )
              } else {
                navigate(`/dashboard/frota/empresa/${user.company.id}/usuario`)
              }
            }
          }}
        >
          Novo {driver ? 'motorista' : 'usuario'}
        </Styled.ConfirmButton>
      </Styled.Header>

      {loading ? (
        <div
          style={{
            width: '100%',
            height: '40rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.Content>
          <DataTable
            data={data}
            setDelete={setDeleteModal}
            selection={selection}
            setSelection={setSelection}
            type="driver-enterprise"
          />
          <Pagination
            className="pagination"
            size="xl"
            total={total}
            radius="md"
            color="27C7FF" 
            withControls={false}
            value={search?.page + 1}
            onChange={(value) => handleChange(value - 1, 'page')}
          />
        </Styled.Content>
      )}
    </Styled.Container>
  )
}
