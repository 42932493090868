import { Menu } from '@mantine/core'
import * as Styled from './styles'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mantine/dates'
import { useEffect, useRef, useState } from 'react'
import dayjs from 'dayjs'
import useClickOutside from '../../../../../../hooks/useClickOutside'
import { ReactComponent as PlaneLandingIcon } from './imgs/plane-landing-icon.svg'
import { ReactComponent as CalendarIcon } from './imgs/calendar-icon.svg'
import { ReactComponent as AlertIcon } from './imgs/alert-icon.svg'
import { HourSelect } from '../../../HourSelect'

export const DateTimePicker = ({
  img,
  imgAlt,
  date,
  time,
  setDate,
  isAirport,
  setTime
}) => {
  const [showTimeInput, setShowTimeInput] = useState(false)
  const [activeTimeOption, setActiveTimeOption] = useState()
  const [open, setOpen] = useState(false)
  const menuDropdownRef = useRef(null)

  const { t } = useTranslation()
  const domNode = useClickOutside(
    () => {
      setOpen(false)
      setShowTimeInput(false)
    },
    menuDropdownRef,
    []
  )

  const formatDate = (dateString) => {
    return dayjs(`${dateString}T${time}`).format('DD/MM/YYYY HH:mm')
  }

  const formattedDate = date
    ? formatDate(date.toISOString().split('T')[0])
    : undefined

  useEffect(() => {
    const option = isAirport ? 'flightArrival' : 'pickup'
    setActiveTimeOption(option)
  }, [isAirport])

  return (
    <Styled.Container>
      <Styled.IconContainer>
        {typeof img === 'string' ? <Styled.Icon src={img} alt={imgAlt} /> : img}
      </Styled.IconContainer>
      <Styled.TextContainer>
        <Styled.Label>{t('reserveComponent.DateAndTime')}</Styled.Label>
        <Menu
          width={200}
          opened={open}
          onClose={() => {
            setOpen(false)
            setShowTimeInput(false)
          }}
          onOpen={() => setOpen(true)}
        >
          <Menu.Target ref={domNode}>
            <Styled.Text>
              {formattedDate
                ? formattedDate
                : !open && t('reserveComponent.whenItWillStart')}
            </Styled.Text>
          </Menu.Target>

          {open && (
            <Menu.Dropdown ref={menuDropdownRef}>
              <Menu.Label>
                {!showTimeInput ? (
                  <DatePicker
                    value={date}
                    onChange={(e) => {
                      setDate(e)
                      setShowTimeInput(true)
                    }}
                    defaultDate={date}
                    minDate={new Date()}
                  />
                ) : (
                  <>
                    <div
                      className="time-toggle"
                      style={!isAirport ? { width: '50%' } : undefined}
                    >
                      {isAirport && (
                        <button
                          onClick={() => setActiveTimeOption('flightArrival')}
                          className={
                            activeTimeOption === 'flightArrival' ? 'active' : ''
                          }
                        >
                          <PlaneLandingIcon />
                          {t('reserveComponent.flightArrivalTime')}
                        </button>
                      )}
                      <button
                        style={!isAirport ? { maxWidth: 'none' } : undefined}
                        onClick={() => setActiveTimeOption('pickup')}
                        className={
                          activeTimeOption === 'pickup' ? 'active' : ''
                        }
                      >
                        <CalendarIcon />
                        {t('reserveComponent.pickup')}
                        {t('reserveComponent.pickup') !==
                          t('reserveComponent.time') && (
                            <>
                              <br />
                              {t('reserveComponent.time')}
                            </>
                          )}
                      </button>
                    </div>
                   <HourSelect
                    onChange={(e) => setTime(e)}
                    value={time}
                   />
                    {activeTimeOption === 'flightArrival' && (
                      <span>
                        <AlertIcon />
                        {t('reserveComponent.alert')}
                      </span>
                    )}
                  </>
                )}
              </Menu.Label>
            </Menu.Dropdown>
          )}
        </Menu>
      </Styled.TextContainer>
    </Styled.Container>
  )
}
