import pt from 'date-fns/locale/pt'
import { formatDuration, intervalToDuration } from 'date-fns'

class DirectionService {
    constructor() {

    }

    extractDistanceFromLegsToKm = (routeDetails) => {
        const legs = routeDetails?.routes[0]?.legs
        const totalDistanceInMeters = legs.reduce(
            (acc, curr) => acc + curr?.distance?.value,
            0
        )

        const totalDistanceInKm = Math.round(totalDistanceInMeters / 1000)
        return Intl.NumberFormat('pt-BR', {
            style: 'unit',
            unit: 'kilometer',
            unitDisplay: 'short'
        }).format(totalDistanceInKm)
    }

    extractDurationFromLegs = (routeDetails) => {
        const legs = routeDetails?.routes[0]?.legs
        const totalDurationInSeconds = legs.reduce(
            (acc, curr) => acc + curr?.duration?.value,
            0
        )

        const duration = intervalToDuration({
            start: 0,
            end: totalDurationInSeconds * 1000
        })

        delete duration.seconds

        return formatDuration(duration, { locale: pt })
    }

}
const directionService = new DirectionService();
export default directionService