/* eslint-disable no-unused-vars */
import { ActionIcon, Checkbox, Group, Input, NumberInput } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import * as Styled from './styles'
import { useState, useRef, useEffect } from 'react'

import Send from './imgs/lucide_map-pin-grey 3.svg'
import Airplane from './imgs/lucide_plane-grey 3.svg'
import Search from './imgs/lucide_search-grey 3.svg'
import MinusBtn from './imgs/icon_minus_blue.webp'
import PlusBtn from './imgs/icon_plus_blue.webp'
import BriefCase from './imgs/icon_briefcase.svg'
import Luggage from './imgs/icon_luggage.svg'
import Backpack from './imgs/icon_backpack.svg'
import Childseat1 from './imgs/childseat1.webp'
import Childseat2 from './imgs/childseat2.webp'
import Childseat3 from './imgs/childseat3.webp'
import aveiroImg from './imgs/aveiro.png'
import ericeiraImg from './imgs/ericeira.png'

import { AddPassenger } from './components/AddPassenger'
import { NavigationTabs } from '../../components/NavigationTabs'
import { PopoverButton } from '../../components/PopoverButton'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

import { Autocomplete } from '@react-google-maps/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { StopSelection } from '../StopSelection'
import fetchOrderBump from '../../../../services/orderBump'

const passengerStops = [
  {
    title: 'Aveiro',
    time: '5 min stop',
    price: '$ 40',
    img: aveiroImg
  },
  {
    title: 'Ericeira',
    time: '5 min stop',
    price: '$ 40',
    img: ericeiraImg
  },
  {
    title: 'Aveiro',
    time: '5 min stop',
    price: '$ 40',
    img: aveiroImg
  },
  {
    title: 'Ericeira',
    time: '5 min stop',
    price: '$ 40',
    img: ericeiraImg
  }
]

export const Transfer = () => {
  const [iAgree, setIAgree] = useState(true)
  const handlers = useRef()
  // const [activeSeat, setActiveSeat] = useState('')
  const [activeTab, setActiveTab] = useState('first')
  const [routeCalculated, setRouteCalculated] = useState(false)
  const [passengerStops, setStops] = useState([])

  /**@type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /**@type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  /**@type React.MutableRefObject<HTMLInputElement> */
  const returnOriginRef = useRef()
  /**@type React.MutableRefObject<HTMLInputElement> */
  const returnDestinationRef = useRef()

  const responsiveSurfAndAnimals = useMediaQuery('(max-width: 944px)')

  const {
    going,
    setGoing,
    returnTravel,
    setReturnTravel,
    calculateRoute,
    carSelected,
    isLoaded
  } = useTransferContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  useEffect(() => {
    const params = {
      startingPoint: going.from,
      arrivingPoint: going.to
    }
    fetchOrderBump.getOrderBumpFindByPoints(params).then((res) => {
      setStops(res?.data?.passengerStops)
    })
  }, [])

  const continueTransfer = () => {
    if (
      originRef?.current?.value === '' ||
      destinationRef?.current?.value === '' ||
      going.from === '' ||
      going.to === '' ||
      going.allPassangers === 0
    ) {
      return toast.error(t('toast.fulfilAllInformations'))
    }

    if (!iAgree) {
      return toast.error(t('toast.termsAndConditionsRule'))
    }

    navigate('/checkout2')
  }

  const IncreaseValue = (value, name, context, setContext) => {
    setContext({ ...context, [name]: value + 1 })
  }

  const DecreaseValue = (value, name, context, setContext) => {
    if (value < 1) {
      return
    } else {
      setContext({ ...context, [name]: value - 1 })
    }
  }

  useEffect(() => {
    if (going.distance && carSelected) {
      const distanceValue = parseFloat(going.distance)

      if (!isNaN(distanceValue)) {
        setGoing((prevGoing) => ({
          ...prevGoing,
          totalPrice: distanceValue * carSelected.price
        }))
      } else {
        console.error('Erro ao converter a distância para número.')
      }
    }
  }, [going.distance, carSelected])

  useEffect(() => {
    if (returnTravel.distance && carSelected) {
      const distanceValue = parseFloat(returnTravel.distance)

      if (!isNaN(distanceValue)) {
        setReturnTravel((prevReturn) => ({
          ...prevReturn,
          totalPrice: distanceValue * carSelected.price
        }))
      } else {
        console.error('Erro ao converter a distância para número.')
      }
    }
  }, [returnTravel.distance, carSelected])

  useEffect(() => {
    if (!routeCalculated) {
      calculateRoute(originRef, destinationRef, going.passengerStops, 'going')
      calculateRoute(returnOriginRef, returnDestinationRef, undefined, 'return')
      setRouteCalculated(true)
    }
  }, [routeCalculated, going.from, going.to, going.passengerStops])

  useEffect(() => {
    if (carSelected) {
      if (going.allPassangers > carSelected.passengers) {
        setGoing({ ...going, allPassangers: carSelected.passengers })
      }
    }
  }, [going, carSelected])

  const firstPanel = (
    <>
      <Input.Wrapper
        id="input-demo"
        label={t('transfer.from')}
        style={{ marginTop: '20px' }}
      >
        <Autocomplete>
          <Input
            required
            icon={<img src={Send} style={{ width: '22px' }} />}
            id="input-demo"
            placeholder="Queen Alia International Airport (AMM)"
            size={'lg'}
            value={going.from}
            ref={originRef}
            onChange={() =>
              setGoing({ ...going, from: originRef.current.value })
            }
            onBlur={() => {
              setGoing({ ...going, from: originRef.current.value })
              setRouteCalculated(false)
            }}
          />
        </Autocomplete>
      </Input.Wrapper>
      <Input.Wrapper id="input-demo" label={t('transfer.to')}>
        <Autocomplete>
          <Input
            icon={<img src={Send} style={{ width: '22px' }} />}
            id="input-demo"
            placeholder="Queen Alia International Airport (AMM)"
            size={'lg'}
            ref={destinationRef}
            value={going.to}
            onChange={() => {
              setGoing({ ...going, to: destinationRef.current.value })
            }}
            onBlur={() => {
              setGoing({ ...going, to: destinationRef.current.value })
              setRouteCalculated(false)
            }}
          />
        </Autocomplete>
      </Input.Wrapper>

      {going.passengerStops.length > 0 &&
        going.passengerStops.map((_, idx) => (
          <Input.Wrapper
            key={idx}
            id="input-demo"
            label={t('reserveComponent.Stop')}
            // style={{ marginTop: '20px' }}
          >
            <Autocomplete>
              <Input
                icon={<img src={Send} style={{ width: '22px' }} />}
                id="input-demo"
                placeholder="Queen Alia International Airport (AMM)"
                size={'lg'}
                value={going.passengerStops[idx].from}
                onChange={(e) => {
                  const newStops = [...going.passengerStops]
                  newStops[idx] = { ...newStops[idx], from: e.target.value }
                  setGoing({ ...going, passengerStops: newStops })
                }}
                onBlur={(e) => {
                  const newStops = [...going.passengerStops]
                  newStops[idx] = { ...newStops[idx], from: e.target.value }
                  setGoing({ ...going, passengerStops: newStops })
                  setRouteCalculated(false)
                }}
              />
            </Autocomplete>
          </Input.Wrapper>
        ))}
      <Input.Wrapper id="input-to" label={t('transfer.flightNumber')}>
        <Input
          icon={<img src={Airplane} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="34774"
          size={'lg'}
          onChange={(e) => setGoing({ ...going, flightNumber: e.target.value })}
          value={going.flightNumber}
          rightSection={
            <img
              style={{ cursor: 'pointer' }}
              onClick={() => setGoing({ ...going, flightNumber: '' })}
              width={22.55}
              height={22.55}
              src={Search}
            />
          }
        />
      </Input.Wrapper>

      <Styled.CardNumber>
        <span>{t('cardNumberVerification.checkNumber')}</span>
        <PopoverButton />
      </Styled.CardNumber>

      <Styled.NumberContainer>
        <label>{t('transfer.passengers')}</label>
        <Group
          spacing={5}
          style={{ justifyContent: 'space-between', position: 'relative' }}
        >
          <ActionIcon
            className="btn-minus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() =>
              DecreaseValue(
                going.allPassangers,
                'allPassangers',
                going,
                setGoing
              )
            }
          >
            <img src={MinusBtn} />
          </ActionIcon>

          <NumberInput
            hideControls
            value={going.allPassangers}
            // onChange={(val) => setValue(val)}
            handlersRef={handlers}
            max={10}
            min={0}
            step={1}
            styles={{ input: { width: '100%', textAlign: 'center' } }}
            style={{ width: '100%' }}
          />

          {going.allPassangers < carSelected?.passengers && (
            <ActionIcon
              className="btn-plus"
              size={60}
              variant="transparent"
              style={{
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none'
              }}
              onClick={() =>
                IncreaseValue(
                  going.allPassangers,
                  'allPassangers',
                  going,
                  setGoing
                )
              }
            >
              <img src={PlusBtn} />
            </ActionIcon>
          )}
        </Group>
      </Styled.NumberContainer>
      <Styled.OptionsContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.backpack')}
            setValue={setGoing}
            value={going.backPack}
            disabledIncreaseAction={
              going.checkedLuggage + going.handLuggage + going.backPack ===
              carSelected?.briefcase
            }
            context={going}
            name="backPack"
            icon={Backpack}
            width="100%"
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.handLuggage')}
            setValue={setGoing}
            value={going.handLuggage}
            disabledIncreaseAction={
              going.checkedLuggage + going.handLuggage + going.backPack ===
              carSelected?.briefcase
            }
            context={going}
            name="handLuggage"
            icon={Luggage}
            width="100%"
          />
        </Styled.NumberContainer>

        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.checkedLuggage')}
            setValue={setGoing}
            value={going.checkedLuggage}
            disabledIncreaseAction={
              going.checkedLuggage + going.handLuggage + going.backPack ===
              carSelected?.briefcase
            }
            context={going}
            name="checkedLuggage"
            icon={BriefCase}
            width="100%"
          />
        </Styled.NumberContainer>
      </Styled.OptionsContainer>

      <Styled.CheckboxOptionsContainer>
        <Checkbox
          size="lg"
          checked={going.childseat}
          onChange={() => setGoing({ ...going, childseat: !going.childseat })}
          label={t('transfer.childseat')}
        />
        {going.childseat && (
          <Styled.ChildCardsWrapper>
            <Styled.ChildCardContainer>
              <Checkbox default />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat1} />
                <Styled.ChildCardText>
                  <h1>{t('checkoutTour.Cadeira de bebê')}</h1>
                  <span>{t('checkoutTour.0 MONTHS - 12 MONTHS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            going.babyChair,
                            'babyChair',
                            going,
                            setGoing
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={going.babyChair}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            going.babyChair,
                            'babyChair',
                            going,
                            setGoing
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat2} />
                <Styled.ChildCardText>
                  <h1>{t('transfer.childseat')}</h1>
                  <span>{t('checkoutTour.1 YEAR - 4 YEARS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            going.childSeat,
                            'childSeat',
                            going,
                            setGoing
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={going.childSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            going.childSeat,
                            'childSeat',
                            going,
                            setGoing
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat3} />
                <Styled.ChildCardText>
                  <h1>{t('checkoutTour.Banco elevatório')}</h1>
                  <span>{t('checkoutTour.4 YEARS - 12 YEARS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            going.boosterSeat,
                            'boosterSeat',
                            going,
                            setGoing
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={going.boosterSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            going.boosterSeat,
                            'boosterSeat',
                            going,
                            setGoing
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
          </Styled.ChildCardsWrapper>
        )}
      </Styled.CheckboxOptionsContainer>
      <Styled.SurfAndAnimalsWrapper>
        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            checked={going.surf}
            onChange={() => setGoing({ ...going, surf: !going.surf })}
            label={t('transfer.surfboard')}
          />
          {going.surf && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                setValue={setGoing}
                value={going.surfQuantity}
                context={going}
                name="surfQuantity"
                width="149.3px"
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>

        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            checked={going.animals}
            onChange={() => setGoing({ ...going, animals: !going.animals })}
            label={t('transfer.animals')}
          />
          {going.animals && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                setValue={setGoing}
                value={going.animalsQuantity}
                name="animalsQuantity"
                width="149.3px"
                context={going}
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>
      </Styled.SurfAndAnimalsWrapper>
      {passengerStops?.length > 0 && (
        <Styled.StopsCarouselWrapper>
          <strong>{t('suggestionsTime')} de paradas:</strong>
          <Carousel
            slideSize="fit-content"
            slideGap="16px"
            align="start"
            slidesToScroll={1}
          >
            {passengerStops.map((stop, idx) => (
              <Carousel.Slide key={idx}>
                <StopSelection
                  onChange={(e) => {
                    console.log(e, 'STOP')
                  }}
                  image={stop.image.url}
                  title={stop.place}
                  time={stop.timeInMinutes}
                  price={stop.price}
                  item={stop}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </Styled.StopsCarouselWrapper>
      )}

      <Styled.CheckedItemsContainer>
        <Styled.CheckedItemWrapper>
          <Checkbox
            size="lg"
            checked={going.shareRide}
            onChange={() => setGoing({ ...going, shareRide: !going.shareRide })}
            label={t('transfer.shareRide')}
          />
          <PopoverButton text={t('transfer.shareRidePopover')} />
        </Styled.CheckedItemWrapper>
        <Styled.CheckedItemWrapper>
          <Checkbox
            size="lg"
            onChange={() => setIAgree(!iAgree)}
            label={t('transfer.iAgree')}
            checked={iAgree}
          />
        </Styled.CheckedItemWrapper>
      </Styled.CheckedItemsContainer>
    </>
  )

  const secondPanel = (
    <>
      <Checkbox
        size="lg"
        checked={!returnTravel.active}
        // styles={{ label: { color: 'black' } }}
        color="orange-primary"
        onChange={() =>
          setReturnTravel({ ...returnTravel, active: !returnTravel.active })
        }
        label="Não reservar volta"
      />
      <Input.Wrapper
        id="input-demo"
        label={t('transfer.from')}
        // style={{ marginTop: '20px' }}
      >
        <Autocomplete>
          <Input
            required
            icon={<img src={Send} style={{ width: '22px' }} />}
            id="input-demo"
            placeholder="Queen Alia International Airport (AMM)"
            size={'lg'}
            ref={returnOriginRef}
            onChange={(e) =>
              setReturnTravel({ ...returnTravel, from: e.target.value })
            } // Atualize diretamente o campo "from" no estado "returnTravel"
            value={returnTravel.from}
            disabled={!returnTravel.active}
            onBlur={() => {
              setReturnTravel({
                ...returnTravel,
                from: returnOriginRef.current.value
              })
              setRouteCalculated(false)
            }}
          />
        </Autocomplete>
      </Input.Wrapper>

      <Input.Wrapper id="input-to" label={t('transfer.to')}>
        <Autocomplete>
          <Input
            icon={<img src={Send} style={{ width: '22px' }} />}
            id="input-demo"
            placeholder="Queen Alia International Airport (AMM)"
            size={'lg'}
            onChange={(e) =>
              setReturnTravel({ ...returnTravel, to: e.target.value })
            }
            value={returnTravel.to}
            ref={returnDestinationRef}
            disabled={!returnTravel.active}
            onBlur={() => {
              setReturnTravel({
                ...returnTravel,
                to: returnDestinationRef.current.value
              })
              setRouteCalculated(false)
            }}
          />
        </Autocomplete>
      </Input.Wrapper>

      <Input.Wrapper id="input-to" label={t('transfer.flightNumber')}>
        <Input
          icon={<img src={Airplane} style={{ width: '18.19px' }} />}
          id="input-demo"
          placeholder="34774"
          disabled={!returnTravel.active}
          size={'lg'}
          onChange={(e) =>
            setReturnTravel({ ...returnTravel, flighNumber: e.target.value })
          }
          value={returnTravel.flighNumber}
          rightSection={
            <img
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setReturnTravel({ ...returnTravel, flighNumber: '' })
              }
              width={22.55}
              height={22.55}
              src={Search}
            />
          }
        />
      </Input.Wrapper>

      <Styled.CardNumber>
        <span>{t('cardNumberVerification.checkNumber')}</span>
        <PopoverButton />
      </Styled.CardNumber>

      <Styled.NumberContainer>
        <label>{t('transfer.passengers')}</label>
        <Group
          spacing={5}
          style={{ justifyContent: 'space-between', position: 'relative' }}
        >
          <ActionIcon
            className="btn-minus"
            size={60}
            variant="transparent"
            disabled={!returnTravel.active}
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() =>
              DecreaseValue(
                returnTravel.allPassangers,
                'allPassangers',
                returnTravel,
                setReturnTravel
              )
            }
          >
            <img src={MinusBtn} />
          </ActionIcon>

          <NumberInput
            hideControls
            value={returnTravel.allPassangers}
            // onChange={(val) => setValue(val)}
            handlersRef={handlers}
            disabled={!returnTravel.active}
            max={10}
            min={0}
            step={1}
            styles={{ input: { width: '100%', textAlign: 'center' } }}
            style={{ width: '100%' }}
          />

          {returnTravel.allPassangers < carSelected?.passengers && (
            <ActionIcon
              className="btn-plus"
              size={60}
              variant="transparent"
              disabled={!returnTravel.active}
              style={{
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none'
              }}
              onClick={() =>
                IncreaseValue(
                  returnTravel.allPassangers,
                  'allPassangers',
                  returnTravel,
                  setReturnTravel
                )
              }
            >
              <img src={PlusBtn} />
            </ActionIcon>
          )}
        </Group>
      </Styled.NumberContainer>
      <Styled.OptionsContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.checkedLuggage')}
            setValue={setReturnTravel}
            value={returnTravel.checkedLuggage}
            context={returnTravel}
            disabled={!returnTravel.active}
            disabledIncreaseAction={
              returnTravel.checkedLuggage +
                returnTravel.handLuggage +
                returnTravel.backPack ===
              carSelected?.briefcase
            }
            name="checkedLuggage"
            icon={BriefCase}
            width="100%"
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.handLuggage')}
            setValue={setReturnTravel}
            value={returnTravel.handLuggage}
            disabled={!returnTravel.active}
            disabledIncreaseAction={
              returnTravel.checkedLuggage +
                returnTravel.handLuggage +
                returnTravel.backPack ===
              carSelected?.briefcase
            }
            context={returnTravel}
            name="handLuggage"
            icon={Luggage}
            width="100%"
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.backpack')}
            setValue={setReturnTravel}
            value={returnTravel.backPack}
            disabled={!returnTravel.active}
            disabledIncreaseAction={
              returnTravel.checkedLuggage +
                returnTravel.handLuggage +
                returnTravel.backPack ===
              carSelected?.briefcase
            }
            context={returnTravel}
            name="backPack"
            icon={Backpack}
            width="100%"
          />
        </Styled.NumberContainer>
      </Styled.OptionsContainer>

      <Styled.SurfAndAnimalsWrapper>
        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            checked={returnTravel.surf}
            disabled={!returnTravel.active}
            onChange={() =>
              setReturnTravel({ ...returnTravel, surf: !returnTravel.surf })
            }
            label={t('transfer.surfboard')}
          />
          {returnTravel.surf && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                setValue={setReturnTravel}
                value={returnTravel.surfQuantity}
                disabled={!returnTravel.active}
                context={returnTravel}
                name="surfQuantity"
                width="149.3px"
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>

        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            disabled={!returnTravel.active}
            checked={returnTravel.animals}
            onChange={() =>
              setReturnTravel({
                ...returnTravel,
                animals: !returnTravel.animals
              })
            }
            label={t('transfer.animals')}
          />
          {returnTravel.animals && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                disabled={!returnTravel.active}
                setValue={setReturnTravel}
                value={returnTravel.animalsQuantity}
                context={returnTravel}
                name="animalsQuantity"
                width="149.3px"
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>
      </Styled.SurfAndAnimalsWrapper>

      <Styled.CheckboxOptionsContainer>
        <Checkbox
          size="lg"
          checked={returnTravel.childseat}
          disabled={!returnTravel.active}
          onChange={() =>
            setReturnTravel({
              ...returnTravel,
              childseat: !returnTravel.childseat
            })
          }
          label={t('transfer.childseat')}
        />
        {returnTravel.childseat && (
          <Styled.ChildCardsWrapper>
            <Styled.ChildCardContainer>
              <Checkbox default />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat1} />
                <Styled.ChildCardText>
                  <h1>{t('checkoutTour.Cadeira de bebê')}</h1>
                  <span>{t('checkoutTour.0 MONTHS - 12 MONTHS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        disabled={!returnTravel.active}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            returnTravel.babyChair,
                            'babyChair',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={returnTravel.babyChair}
                        disabled={!returnTravel.active}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        disabled={!returnTravel.active}
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            returnTravel.babyChair,
                            'babyChair',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat2} />
                <Styled.ChildCardText>
                  <h1>{t('transfer.childseat')}</h1>
                  <span>{t('checkoutTour.1 YEAR - 4 YEARS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            returnTravel.childSeat,
                            'childSeat',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={returnTravel.childSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            returnTravel.childSeat,
                            'childSeat',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat3} />
                <Styled.ChildCardText>
                  <h1>Banco elevatório</h1>
                  <span>4 years - 12 years</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            returnTravel.boosterSeat,
                            'boosterSeat',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={returnTravel.boosterSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            returnTravel.boosterSeat,
                            'boosterSeat',
                            returnTravel,
                            setReturnTravel
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
          </Styled.ChildCardsWrapper>
        )}
      </Styled.CheckboxOptionsContainer>

      <Styled.CheckedItemsContainer>
        <Styled.CheckedItemWrapper>
          <Checkbox
            size="lg"
            checked={going.shareRide}
            onChange={() => setGoing({ ...going, shareRide: !going.shareRide })}
            label={t('transfer.shareRide')}
          />
          <PopoverButton text="Se habilitar esta opção, receberá 20% de desconto que serão reembolsados se compartilhar o transfer com outra pessoa." />
        </Styled.CheckedItemWrapper>
        <Styled.CheckedItemWrapper>
          <Checkbox
            size="lg"
            onChange={() => setIAgree(!iAgree)}
            label={t('transfer.iAgree')}
            checked={iAgree}
          />
        </Styled.CheckedItemWrapper>
      </Styled.CheckedItemsContainer>
    </>
  )

  if (!isLoaded) {
    return <div>teste</div>
  }

  return (
    <Styled.Container>
      {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <GoogleMap
          center={{ lat: 48.8584, lng: 2.2945 }}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false
          }}
        >
          <Marker position={{ lat: 48.8584, lng: 2.2945 }} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </div> */}

      <h1>Transfer</h1>
      <NavigationTabs
        firstPanel={firstPanel}
        secondPanel={secondPanel}
        secondPanelActive
        onTabChange={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Styled.ButtonContainer>
        {/* <Styled.JumpButton>{t('transfer.jumpButton')}</Styled.JumpButton> */}
        {activeTab === 'first' && (
          <Styled.SaveButton
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              setActiveTab('second')
            }}
          >
            {t('transfer.continueButton')}
          </Styled.SaveButton>
        )}
        {activeTab === 'second' && (
          <Styled.SaveButton onClick={continueTransfer}>
            {t('transfer.continueButton')}
          </Styled.SaveButton>
        )}
      </Styled.ButtonContainer>
    </Styled.Container>
  )
}
