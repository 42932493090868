import { useJsApiLoader } from '@react-google-maps/api'
import { createContext, useContext, useState } from 'react'
import { libraries } from './api/libraries'

export const MapsContext = createContext(null)

export const MapsContextProvider = ({ children }) => {
  let googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  if (!googleMapsApiKey) {
    googleMapsApiKey = 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg'
}
  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg', // LOCAL
    googleMapsApiKey: googleMapsApiKey, // PROD
    libraries: libraries
  })

  return (
    <MapsContext.Provider
      value={{
        isLoaded
      }}
    >
      {children}
    </MapsContext.Provider>
  )
}

export function useMaps() {
  return useContext(MapsContext)
}
