import {
  Group,
  Image,
  Menu,
  UnstyledButton,
  createStyles,
  rem
} from '@mantine/core'
import { ArrowUpRight } from 'lucide-react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as Styled from './styles'
import { useState } from 'react'
import portugal from './images/portugal.svg'
import alemanha from './images/germany.svg'
import estadosUnidos from './images/united-states-of-america.svg'
import franca from './images/france.svg'
import nederland from './images/netherlands.svg'
import helpIcon from './images/helpIcon.svg'

import iconChevronDown from './images/IconChevronDown.svg'
import { ReactComponent as EasyTransferLogo } from './images/EasyTransfer - logo oficial.svg'
import { ReactComponent as DarkEasyTransferLogo } from './images/EasyTransfer - logo full white.svg'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { SidebarToggle } from './components/SidebarToggle'
import { ExpandableLink } from './components/ExpandableLink'
import { useMediaQuery } from '../../hooks/useMediaQuery'

const data = [
  { title: 'Portuguese', image: portugal, language: 'pt', country: 'Portugal' },
  { title: 'Dutch', image: nederland, language: 'nl', country: 'Netherlands' },
  { title: 'Deutsch', image: alemanha, language: 'de', country: 'Germany' },
  {
    title: 'English',
    image: estadosUnidos,
    language: 'en',
    country: 'United States'
  },
  { title: 'France', image: franca, language: 'fr', country: 'France' }
]

const useStyles = createStyles((theme, { opened, transparent }) => ({
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    border: transparent
      ? `${rem(1)} solid #0000004D`
      : `${rem(1)} solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[2]
        }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : transparent
        ? 'transparent'
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0]
    }
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)'
  },
  dropdown: {
    width: '15rem !important',
    zIndex: '999999999 !important'
  },
  darkDropdown: {
    width: '15rem !important',
    backgroundColor: 'black',
    border: '1px solid #1f1e1e',
    zIndex: '999999999 !important',
    '[data-hovered]': {
      backgroundColor: '#0D0D0D'
    }
  }
}))

export const Navbar = ({
  dark = false,
  checkout = false,
  transparent = false
}) => {
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened, transparent })
  const [selected, setSelected] = useState(data[0])
  const [language, setLanguage] = useState()
  console.log(language)
  const { i18n } = useTranslation()
  const allowedTransparentMatch = useMediaQuery('(min-width: 768px)')

  const changeLanguage = (event) => {
    i18n.changeLanguage(event)
    console.log(event)
    localStorage.setItem('language', event)
  }

  const items = data.map((item) => (
    <Menu.Item
      icon={
        <Image
          src={item.image}
          width={30}
          height={20}
          alt={`Bandeira do ${item.country}`}
        />
      }
      onClick={() => {
        setSelected(item)
        localStorage.setItem('languageSelected', JSON.stringify(item))
        changeLanguage(item.language.replace(/"/g, ''))
        setLanguage(item.language.replace(/"/g, ''))
      }}
      style={!dark ? undefined : { color: 'white' }}
      key={item.title}
    >
      <option
        value={item.language}
        style={{ fontSize: '1.2rem' }}
        onClick={() => {
          changeLanguage(item.language.replace(/"/g, ''))
          setLanguage(item.language.replace(/"/g, ''))
        }}
      >
        {item.title}
      </option>
    </Menu.Item>
  ))

  // useEffect(() => {
  //   if (language) {
  //     changeLanguage(language)
  //   }
  // }, [language])

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    const language = localStorage.getItem('languageSelected')
    const languageSelected = JSON.parse(language)
    if (savedLanguage) {
      changeLanguage(savedLanguage)
    }
    if (languageSelected) {
      setSelected(languageSelected)
    }
  }, [])

  const { t } = useTranslation()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const Logo = dark ? DarkEasyTransferLogo : EasyTransferLogo

  const services = [
    {
      title: t('navbar.transfer'),
      to: '/'
    },
    {
      title: t('navbar.tours'),
      to: '/tour'
    },
    {
      title: t('navbar.events'),
      to: '/eventos'
    },
    {
      title: t('navbar.corporate'),
      to: '/corporate'
    },
    {
      title: t('navbar.weddings'),
      to: 'https://easytransfer.services/casamentos/',
      blank: true
    },
    {
      title: t('navbar.school'),
      to: 'https://easytransfer.services/escola/',
      blank: true
    },
    {
      title: t('navbar.privateDriver'),
      to: 'https://easytransfer.services/motorista-particular/',
      blank: true
    }
  ]

  const workWithUs = [
    {
      title: t('navbar.beDriver'),
      to: '/trabalhe-conosco-motorista'
    },
    {
      title: t('navbar.bePartner'),
      to: '/trabalhe-conosco-empresa'
    }
  ]

  return (
    <Styled.Container
      dark={dark}
      transparent={allowedTransparentMatch && transparent}
    >
      <Styled.Navbar>
        <Link to="/">
          <Logo id="logo" />
        </Link>
        {!checkout && (
          <>
            <Link
              to="/"
              onClick={
                pathname === '/'
                  ? (e) => {
                      e.preventDefault()
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      })
                    }
                  : undefined
              }
              style={{ textDecoration: 'none' }}
            >
              <Styled.Link>{t('navbar.start')}</Styled.Link>
            </Link>
            <a
              onClick={
                pathname === '/'
                  ? undefined
                  : (e) => {
                      e.preventDefault()
                      navigate('/#about')
                    }
              }
              href="#about"
              style={{ textDecoration: 'none' }}
            >
              <Styled.Link>{t('navbar.aboutUs')}</Styled.Link>
            </a>
            <Link to="/fleet" style={{ textDecoration: 'none' }}>
              <Styled.Link>{t('navbar.fleet')}</Styled.Link>
            </Link>
            <ExpandableLink title={t('navbar.services')} subItems={services} />
            <ExpandableLink
              title={t('navbar.workWithUs')}
              subItems={workWithUs}
            />
            {/* <Link to="/corporate" style={{ textDecoration: 'none' }}>
              <Styled.Link>{t('navbar.corporate')}</Styled.Link>
            </Link> */}
            {/* {pathname === '/' && ( */}
            <>
              <a
                onClick={
                  pathname === '/'
                    ? undefined
                    : (e) => {
                        e.preventDefault()
                        navigate('/#faq')
                      }
                }
                href="https://easytransfer.services/faq/"
                style={{ textDecoration: 'none' }}
              >
                <Styled.Link>FAQ</Styled.Link>
              </a>
              {/* <a href="#" style={{ textDecoration: 'none' }}>
                <Styled.Link>Blog</Styled.Link>
              </a> */}
            </>
            {/* )} */}
            {/*
            <Link to="/eventos" style={{ textDecoration: 'none' }}>
              <Styled.Link>{t('navbar.events')}</Styled.Link>
            </Link>
            <Link to="/tours" style={{ textDecoration: 'none' }}>
              <Styled.Link>{t('navbar.tours')}</Styled.Link>
            </Link> */}
            {/* <Link to="/reembolso" style={{ textDecoration: 'none' }}>
              <Styled.Link>{t('navbar.reimbursement')}</Styled.Link>
            </Link> */}
            <a href="/#transfer">
              <Styled.Button>
                {t('navbar.book now')}
                <ArrowUpRight size={18} />
              </Styled.Button>
            </a>
          </>
        )}
        <div
          style={{
            zIndex: '9999999999999',
            display: 'flex',
            alignItems: 'center',
            gap: '25px'
          }}
        >
          {checkout && (
            <>
              <Styled.HelpTitle>
                Help <img src={helpIcon} />
              </Styled.HelpTitle>

              <Styled.MoneyDropdown>
                € <img src={iconChevronDown} />
              </Styled.MoneyDropdown>
            </>
          )}
          <Menu
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            radius="md"
            width="target"
            style={
              !dark
                ? undefined
                : {
                    backgroundColor: 'black',
                    color: 'white',
                    border: '1px solid #141414'
                  }
            }
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={classes.control}>
                <Group spacing="xs">
                  <Image
                    src={selected.image}
                    width={40}
                    height={30}
                    alt={`Bandeira do ${selected.country}`}
                  />
                  <span className={classes.label}>{selected.label}</span>
                </Group>
                <img src={iconChevronDown} alt="Flecha de seleção" />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown
              className={!dark ? classes.dropdown : classes.darkDropdown}
            >
              {items}
            </Menu.Dropdown>
          </Menu>
        </div>
        <Styled.NavbarWrapper>
          <SidebarToggle />
        </Styled.NavbarWrapper>
      </Styled.Navbar>
    </Styled.Container>
  )
}
