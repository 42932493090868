import React from 'react'

import { useAuth } from '../../../../../../contexts/useAuth'
import { DateFormatter } from './utils/date_formatter'
import { Loader } from '@mantine/core'

import * as Styled from './styled'

export function MessageBubble({ message, sender, nextSenderId }) {
  const { user } = useAuth()

  const sentByMe = sender?.id === user.id
  const isSameAsBefore = nextSenderId === sender?.id

  return (
    <Styled.MessageContainer
      sentByMe={sentByMe}
      isSameAsBefore={isSameAsBefore}
    >
      <Styled.MessageContentWrapper sentByMe={sentByMe}>
        <SenderProfile
          sender={sender}
          sentByMe={sentByMe}
          isSameAsBefore={isSameAsBefore}
        />

        <Message message={message} sentByMe={sentByMe} />
      </Styled.MessageContentWrapper>
    </Styled.MessageContainer>
  )
}

function SenderProfile({ sender, sentByMe, isSameAsBefore }) {
  if (!sender) return <></>

  return (
    <Styled.UserWrapper
      style={{ alignItems: sentByMe ? 'flex-start' : 'flex-end' }}
    >
      {!isSameAsBefore && (
        <>
          <Styled.ProfileImg src={sender?.profilePicture?.url} />
          <Styled.UserName>{sender.name}</Styled.UserName>
        </>
      )}
    </Styled.UserWrapper>
  )
}

function Message({ message, sentByMe }) {
  const sentAt = DateFormatter.getFriendlyDate(message?.sentAt)

  return (
    <Styled.BubbleWrapper sentByMe={sentByMe}>
      <Styled.Message>{message?.content}</Styled.Message>

      {message?.sentAt && <Styled.SendDate>{sentAt}</Styled.SendDate>}
      {!message?.sentAt && <Loader color="27C7FF"  size="sm" />}
    </Styled.BubbleWrapper>
  )
}
