import styled, { css } from 'styled-components'


export const SectionPriceInformationContainer = styled.section`
    width: 100%;
    height: 870px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media (max-width: 768px) {
        padding: 16px;
    }
`

export const SectionPriceInformationContentContainer = styled.div`
    max-width: 766px;
    max-height: 650px;
    width: 100%;
    height: 100%;
`

export const PriceInformationRow = styled.div`
    width: 100%;
    height: 100%;
    max-height: 100px;
    display: flex;
    justify-content: center;
    color: currentColor;
    
    ${props => props.edge && css`max-height: 12.59rem`}
`

export const MiddleColumnTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 2.4rem;
    border-top-left-radius: 2.4rem;

    max-width: 255.33px;
    width: 100%;
    height: 100%;
    @media (max-width: 500px) {
        width:50%; 
    }
    
    color: #fff;

    border: 4px solid #1D95BF;
    border-bottom: none;

    ${({ theme }) => css`
        background-color: ${theme.colors.primary};
  `}
`

export const MiddleColumn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;


    ${({ theme }) => css`
        background-color: ${theme.colors.primary};
    `}

    max-width: 255.33px;
    max-height: 100px;
    width: 100%;
    border: 4px solid #1D95BF;
    border-bottom: none;
    border-top: none;
    font-weight: 500;
    color: #fff;
    @media (max-width: 500px) {
        width:50%;        
    }
    
    
`
export const MiddleColumnBottom = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 255.33px;
    max-height: 100px;
    width: 100%;
    border-bottom-right-radius: 2.4rem;
    border-bottom-left-radius: 2.4rem;

    border: 4px solid #1D95BF;
    border-top: none;

    font-size: 2.4rem;
    font-weight: bold;
    @media (max-width: 500px){
        max-width: 50% !important;
    }

    ${({ theme }) => css`
        background-color: ${theme.colors.primary};
  `}    
`

export const DefaultColumnTop = styled.div`
    max-width: 255.33px;
    max-height: 100px;
    width: 100%;
    border: 2px solid #27c7ff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;
    color: #808080;
    @media (max-width: 500px){
        width: 25% !important;
    }

    ${props => props.left && css`border-top-left-radius: 2.4rem; border-right: none`}
    ${props => props.right && css`border-top-right-radius: 2.4rem; border-left: none`}
`

export const DefaultColumn = styled.div`
    max-width: 255.33px;
    max-height: 100px;
    width: 100%;
    border: 2px solid #27c7ff;
    color: #808080;
    border-top: none;
    font-size: 2.4rem;

    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 500px){
        width: 25% !important;
    }
    
    ${props => props.left && css`border-right: none`}
    ${props => props.right && css`border-left: none`}
`

export const DefaultColumnBottom = styled.div`
    max-width: 255.33px;
    max-height: 100px;
    width: 100%;
    @media (max-width: 500px){
        width: 25% !important;
    }
    

    border: 2px solid #27c7ff;
    border-top: none;
    color: #808080;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;

    ${props => props.left && css`border-bottom-left-radius: 2.4rem; border-right: none`}
    ${props => props.right && css`border-bottom-right-radius: 2.4rem; border-left: none `}
`


export const InformationContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2.4rem;
    font-weight: bold;
    >img {
        @media (max-width: 500px) {
          padding: 4px;
          width: -webkit-fill-available;
      }
    }
    >.uber,.bolt {
        @media (max-width: 500px) {
          width: 70%;
      }
    }
    @media (max-width: 500px) {
          font-size: 2rem !important;
      }

  `

export const MiddleColumnText = styled.p`
    font-size: 1.6rem;
    font-weight: 500;
    text-align: center;
`

export const DefaultColumnText = styled.p`
    font-size: 2.4rem;
    font-weight: bold;
    @media (max-width: 500px) {
          font-size: 2rem !important;
      }

`

export const BookingButton = styled.a`
    display: flex;
    padding: 1.6rem 5.7rem;
    background-color: #fff;
    color: #27c7ff;
    text-align: center;
    font-weight: bold;
    border-radius: 0.7rem;
    font-size: 1.36rem;
    @media (max-width: 500px) {
          justify-content: center;
          width: 50%;
      }

`

export const PriceInformation = styled.div`
    padding: 1rem;
    border-radius: 4px;
    text-align: center;
    background: #9AD7ED;
    color: #005775;
    font-size: 1.5rem;
;

`