import fleet from './imgs/fleet.png'
import fleet2 from './imgs/fleet-2.png'
import fleet3 from './imgs/fleet-3.png'
import fleet4 from './imgs/fleet-4.png'
import fleet5 from './imgs/fleet-5.png'

export const Fleet = (t) => [
  {
    title: t('fleet.economic'),
    desc: <p>4 {t('fleet.peoples')}</p>,
    img: fleet,
    alt: 'Frota econômica'
  },
  {
    title: t('fleet.executive'),
    desc: <p>4 {t('fleet.peoples')}</p>,
    img: fleet2,
    alt: 'Frota executiva'
  },
  {
    title: t('fleet.van'),
    desc: <p>8 {t('fleet.peoples')}</p>,
    img: fleet3,
    alt: 'Frota van'
  },
  {
    title: t('fleet.bus'),
    desc: <p>56 {t('fleet.peoples')}</p>,
    img: fleet4,
    alt: 'Frota de autocarro'
  },
  {
    title: t('fleet.airplane'),
    desc: <p>3 {t('fleet.peoples')}</p>,
    img: fleet5,
    alt: 'Frota de aeronave'
  }
]
