import * as Styled from './styles'
import easyTransferLogo from '../../../../../Dashboard/pages/DriverRecruitment/img/EasyTransfer - logo black.svg'
import UberLogo from './../../../../../Dashboard/pages/DriverRecruitment/img/uber.svg'
import BoltLogo from './../../../../../Dashboard/pages/DriverRecruitment/img/Bolt.svg'
import { XCircle, CheckCircle2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const SectionAdvantagesInformation = () => {

    const { t } = useTranslation()

    return (
        <Styled.SectionPriceInformationContainer>
            <Styled.SectionPriceInformationContentContainer>
                <Styled.PriceInformationRow edge>
                    <Styled.InformationContent>
                        <img className='uber' src={UberLogo} />
                        <Styled.PriceInformation>€ 40-97</Styled.PriceInformation>
                    </Styled.InformationContent>
                    <Styled.MiddleColumnTop>
                        <Styled.InformationContent>
                            <img src={easyTransferLogo} width={'100%'} />
                            <span style={{ fontSize: '2rem' }}>€ 35-55</span>
                        </Styled.InformationContent>
                    </Styled.MiddleColumnTop>
                    <Styled.InformationContent>
                        <img className='bolt' src={BoltLogo} />
                        <Styled.PriceInformation>€ 65-95</Styled.PriceInformation>
                    </Styled.InformationContent>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumnTop left>
                        <Styled.InformationContent>
                            <XCircle size={22} />
                        </Styled.InformationContent>
                    </Styled.DefaultColumnTop>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                            <Styled.DefaultColumnText>
                                <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            </Styled.DefaultColumnText>
                            <Styled.MiddleColumnText>{t('fluentDriver')}</Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumnTop right>
                        <Styled.DefaultColumnText>
                            <XCircle size={22} />
                        </Styled.DefaultColumnText>
                    </Styled.DefaultColumnTop>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumn left>
                        <CheckCircle2 size={25} fill="#b3b3b3" color='#fff'/>
                    </Styled.DefaultColumn>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                        <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            <Styled.MiddleColumnText>
                                {t('transferAndTaxiService')}
                            </Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumn right>
                        <CheckCircle2 size={25} fill="#b3b3b3" color='#fff'/>
                    </Styled.DefaultColumn>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumn left>
                        <Styled.DefaultColumnText>
                            <XCircle size={22} />
                        </Styled.DefaultColumnText>
                    </Styled.DefaultColumn>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                            <Styled.DefaultColumnText>
                            <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            </Styled.DefaultColumnText>
                            <Styled.MiddleColumnText>
                                {t('tourGuideService')}
                            </Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumn right>
                        <Styled.DefaultColumnText>
                            <CheckCircle2 size={25} fill="#b3b3b3" color='#fff'/>
                        </Styled.DefaultColumnText>
                    </Styled.DefaultColumn>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumn left>
                        <XCircle size={22} />
                    </Styled.DefaultColumn>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                        <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            <Styled.MiddleColumnText>{t('airTourService')}</Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumn right>
                        <XCircle size={22} />
                    </Styled.DefaultColumn>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumn left>
                        <CheckCircle2 size={25} fill="#b3b3b3" color='#fff'/>
                    </Styled.DefaultColumn>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                        <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            <Styled.MiddleColumnText>{t('driverChat')}</Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumn right>
                        <XCircle size={22} />
                    </Styled.DefaultColumn>
                </Styled.PriceInformationRow>
                <Styled.PriceInformationRow>
                    <Styled.DefaultColumnBottom left>
                        <CheckCircle2 size={25} fill="#b3b3b3" color='#fff'/>
                    </Styled.DefaultColumnBottom>
                    <Styled.MiddleColumn>
                        <Styled.InformationContent>
                        <CheckCircle2 size={22} fill='#fff' color='#27c7ff' />
                            <Styled.MiddleColumnText>{t('24HoursSupport')}</Styled.MiddleColumnText>
                        </Styled.InformationContent>
                    </Styled.MiddleColumn>
                    <Styled.DefaultColumnBottom right>
                    <XCircle size={22} />
                    </Styled.DefaultColumnBottom>
                </Styled.PriceInformationRow>

                <Styled.PriceInformationRow edge>
                    <div>
                        <p></p>
                    </div>
                    <Styled.MiddleColumnBottom>
                        <Styled.InformationContent>
                            <Styled.BookingButton  href="#transfer">{t('priceTable.bookNow')}</Styled.BookingButton>
                        </Styled.InformationContent>
                    </Styled.MiddleColumnBottom>
                    <div>
                        <p></p>
                    </div>
                </Styled.PriceInformationRow>
            </Styled.SectionPriceInformationContentContainer>
        </Styled.SectionPriceInformationContainer>
    )
}
