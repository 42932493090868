import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useTour } from '../../useTour'

import fetchTour, { query, token } from '../../tours'

import { Rating } from '@mantine/core'

import {
  CardDescription,
  CardRating,
  CardTitle,
  CardTour,
  Container,
  ContainerCardTours,
  FormLabel,
  Select,
  Tag,
  TopHeader
} from './styles'

import { ChevronRight, MapPin } from 'lucide-react'
import axios from 'axios'
import {
  CardMostChosen,
  CardMostChosenBody,
  CardMostChosenImage,
  CardMostChosenRating,
  CardMostChosenSeeMore,
  CardMostChosenTime,
  CardMostChosenTitle,
  ContainerCards
} from '../MostChosen/styles'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

export default function Tours() {
  const [loadingTours, setLoadingTours] = useState(true)
  const { data, setData, citySelected, setCitySelected, setSelected, tours } =
    useTour()
  const { t } = useTranslation()
  const isMobile = useMediaQuery('(max-width: 500px)')
  const navigate = useNavigate()
  const language = localStorage.getItem('language')

  console.log(language, 'LANGUAGE')

  const getSelect = () => {
    setLoadingTours(true)
    axios
      .post(
        `https://strapi.easytransferdrivers.site/graphql`,
        {
          query: query(language)
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        const response = res?.data?.data?.cityTours?.data

        setData(response)

        setSelected(res?.data?.data?.cityTours?.data[1])

        setCitySelected(
          res?.data?.data?.cityTours?.data[1]?.attributes?.cityName
        )

        setLoadingTours(false)
      })
      .catch((err) => {
        console.log(err)
        setLoadingTours(false)
      })
  }

  useEffect(() => {
    getSelect()
  }, [language])

  const handleSelect = (value) => {
    const selected = data.find((item) => item.attributes.cityName === value)

    setCitySelected(selected?.attributes.cityName)

    setSelected(selected)
  }

  const goToTour = (tour) => {
    navigate(`/tour/${tour.attributes.name}`, {
      state: tour
    })
    console.log(tour, 'TOUR!')
  }

  return (
    <Container>
      <TopHeader>
        <MapPin color="#27c7ff" />
        <h2>{t('tours.chooseFavoriteCity')}</h2>
      </TopHeader>
      <FormLabel
        activeItemIdx={data.findIndex(
          (item) => item?.attributes.cityName === citySelected
        )}
      >
        {/* <MapPin color="#27c7ff" />

        <Select
          value={citySelected}
          onChange={(e) => handleSelect(e.target.value)}
        >
          <option value="">Selecione</option>
          {data.map((option) => (
            <option
              key={option?.id}
              value={option?.attributes.cityName}
              label={option?.attributes.cityName}
            >
              {option?.attributes.cityName}
            </option>
          ))}
        </Select> */}
        <div>
          {data.map((option) => (
            <Tag
              key={option?.id}
              active={citySelected === option?.attributes.cityName}
              onClick={() => handleSelect(option?.attributes.cityName)}
            >
              {option?.attributes.cityName}
            </Tag>
          ))}
        </div>
        {isMobile && <ChevronRight />}
      </FormLabel>

      {loadingTours && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '300px',
            width: '100%'
          }}
        >
          <ClipLoader loading={loadingTours} size={50} color="#27c7ff" />
        </div>
      )}
      <ContainerCards>
        {!loadingTours &&
          tours?.map((item, index) => (
            <CardMostChosen key={index} onClick={() => goToTour(item)}>
              <CardMostChosenImage
                image={item?.attributes?.mainImage?.data?.attributes?.url}
              />
              <CardMostChosenBody>
                <div>
                  <CardMostChosenTitle>
                    {item?.attributes.name}
                  </CardMostChosenTitle>
                  <CardMostChosenRating>
                    <Rating
                      value={item?.attributes.rate}
                      fractions={2}
                      readOnly
                      size="xl"
                    />
                    <p>{item.attributes.rate}</p>
                  </CardMostChosenRating>
                </div>
                <CardMostChosenTime>
                  <h5>{t('tours.duration')}</h5>
                  <p>
                    {item?.attributes.durationInHours} {t('tours.hours')}
                  </p>
                </CardMostChosenTime>
                <CardMostChosenSeeMore>
                  <p>
                    €
                    {formatCurrency(item?.attributes.providerPrice).replace(
                      '€',
                      ''
                    )}
                    <span>/ {t('tours.tour')}</span>
                  </p>

                  <button>{t('seeMore')}</button>
                </CardMostChosenSeeMore>
              </CardMostChosenBody>
            </CardMostChosen>
          ))}
      </ContainerCards>

      {/* <ContainerCardTours>
        {tours?.map((item, index) => (
          <Link key={index} to={`/tour/${item?.attributes.name}`} state={item}>
            <CardTour
              image={item?.attributes?.mainImage?.data?.attributes?.url}
            >
              <CardDescription>
                <div>
                  <CardTitle>
                    {item?.attributes.name.length > 35
                      ? `${item?.attributes.name.slice(0, 35)}...`
                      : item?.attributes.name}
                  </CardTitle>
                  <CardRating>
                    <Rating
                      value={item?.attributes.rate}
                      fractions={2}
                      readOnly
                      size="xl"
                    />

                    <p>{item?.attributes.rate}</p>
                  </CardRating>
                </div>

                <ChevronRight color="#fff" />
              </CardDescription>
            </CardTour>
          </Link>
        ))}
      </ContainerCardTours> */}
    </Container>
  )
}
