import { BadgeEuro, Wallet } from 'lucide-react'
import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

export const Counters = ({
  options,
  setValue,
  value,
  total = 0,
  netTotal = 0,
  totalBalance = 0,
  filters
}) => {
  return (
    <Styled.Container>
      <Styled.Options>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(total)}</Styled.OptionValue>
            <Styled.OptionUpdate>{filters && filters.label ? filters.label : 'Todos os registros'}</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL LÍQUIDO</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(netTotal)}</Styled.OptionValue>
            <Styled.OptionUpdate>{filters && filters.label ? filters.label : 'Todos os registros'}</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>CARTEIRA</Styled.OptionTitle>
            <Wallet color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>
              {formatCurrency(totalBalance)}
            </Styled.OptionValue>
            <Styled.OptionUpdate>{filters && filters.label ? filters.label : 'Todos os registros'}</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
      </Styled.Options>
    </Styled.Container>
  )
}
