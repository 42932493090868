import standard from '../../../Fleet/imgs/car1.webp'
import executive from '../../../Fleet/imgs/car2.webp'
import peopleCarrier from '../../../Fleet/imgs/car3.webp'
import largePeopleCarrier from '../../../Fleet/imgs/car4.webp'
import executivePeopleCarrier from '../../../Fleet/imgs/car5.webp'
import miniBus from '../../../Fleet/imgs/car6.webp'
import Autocar from '../../../Fleet/imgs/car7.webp'

export const CarItem = [
  {
    img: standard,
    title: 'Standard',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 4
  },
  {
    img: executive,
    title: 'Executive',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    price: 6
  },
  {
    img: peopleCarrier,
    title: 'XL',
    briefcase: 5,
    wifi: true,
    passengers: 5,
    price: 8
  },
  {
    img: largePeopleCarrier,
    title: 'Van',
    briefcase: 8,
    wifi: true,
    passengers: 8,
    price: 10
  },
  {
    img: executivePeopleCarrier,
    title: 'Executive Van',
    briefcase: 8,
    wifi: true,
    passengers: 8,
    price: 12
  },
  {
    img: miniBus,
    title: 'Mini Bus',
    briefcase: 16,
    wifi: true,
    passengers: 16,
    price: 14
  },
  {
    img: Autocar,
    title: 'Mini Bus',
    briefcase: 56,
    wifi: true,
    passengers: 56,
    price: 14
  }
]
