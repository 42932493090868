import { Navbar } from '../../components/Navbar'
import { Header } from './Header'
import { Transfer } from './components/Transfer'
import * as Styled from './styles'
import { Steppers } from './components/Steppers'
import { ChoiceCars } from './components/ChoiceCars'
import { useEffect, useState } from 'react'
import { useTransferContext } from '../../contexts/TransferContext'
import Footer from '../../components/Footer'
import { RightContainer } from './RightContainer'
import { NavbarCheckout } from '../../components/NavbarCheckout'
import fetchCategory from '../../services/carCategory'
export const Checkout = () => {
  const {
    setCarItem,
    setCarPagination,
    carPagination,
    carSelected,
    setCarSelected
  } = useTransferContext()

  const [showCars, setShowCars] = useState(carSelected ? false : true)

  useEffect(() => {
    const fetchCars = async () => {
      const params = {
        deleted: false,
        size: 6,
        page: carPagination.page
      }
      try {
        const res = await fetchCategory.getAll(params)

        const mapCars = res.data.content.map((item) => ({
          ...item,
          briefcase:
            item.luggageCapacity +
            item.handLuggageCapacity +
            item.backpackCapacity,
          passengers: item.passengerCapacity,
          wifi: item.providesInternet,
          price: item.kmPerLiter,
          img: item.file.url,
          title: item.name
        }))
        setCarItem(mapCars)
        setCarPagination({
          ...carPagination,
          totalElements: res.data.totalElements,
          totalPages: res.data.totalPages
        })
      } catch (error) {
        console.error('Failed to fetch cars:', error)
      }
    }

    fetchCars()
  }, [carPagination.page])

  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={1} />
        {showCars && (
          <Styled.ChoiceCarsContainer showCars={showCars}>
            <ChoiceCars
              setSelectedCar={setCarSelected}
              setShowCars={setShowCars}
            />
          </Styled.ChoiceCarsContainer>
        )}
        {!showCars && (
          <Styled.ContentContainer>
            <Styled.MiddleContainerRow showCars={showCars}>
              <Transfer />
              <RightContainer
                carSelected={carSelected}
                setShowCars={setShowCars}
                showCars={showCars}
              />
            </Styled.MiddleContainerRow>
          </Styled.ContentContainer>
        )}
      </Styled.Container>
      <Footer />
    </>
  )
}
