import { useContext } from "react";
import APIContext from "../contexts/api/APIContext";

function useAPI() { 
    const context = useContext(APIContext)

    if (!context) {
        throw new Error('useAPI must be used within an APIContextProvider')
    }

    return context
}

export default useAPI