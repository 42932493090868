import * as Styled from './styles'
import easyTransferLogo from '../../../../../Dashboard/pages/DriverRecruitment/img/EasyTransfer - logo black.svg'
import UberLogo from './../../img/uber.svg'
import BoltLogo from './../../img/Bolt.svg'
import CheckIcon from './../../img/check-icon.svg'
import { XCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

export const SectionPriceInformation = () => {
    const { t } = useTranslation()

    return (
        <Styled.SectionPriceInformationContainer>
        <Styled.SectionPriceInformationContentContainer>
          <Styled.PriceInformationRow edge>
            <Styled.InformationContent>
              <img className='uber' src={UberLogo} />
            </Styled.InformationContent>
            <Styled.MiddleColumnTop>
              <Styled.InformationContent>
                <img src={easyTransferLogo} />
              </Styled.InformationContent>
            </Styled.MiddleColumnTop>
            <Styled.InformationContent>
              <img className='bolt' src={BoltLogo} />
            </Styled.InformationContent>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow>
            <Styled.DefaultColumnTop left>
              <Styled.DefaultColumnText>
                <span style={{ fontSize: '2rem' }}>€</span> 0.30
              </Styled.DefaultColumnText>
            </Styled.DefaultColumnTop>
            <Styled.MiddleColumn>
              <Styled.InformationContent>
                <Styled.DefaultColumnText>
                  <span style={{ fontSize: '2rem' }}>€</span> 0.70
                </Styled.DefaultColumnText>
                <Styled.MiddleColumnText>{t('priceTable.kmValue')}</Styled.MiddleColumnText>
              </Styled.InformationContent>
            </Styled.MiddleColumn>
            <Styled.DefaultColumnTop right>
              <Styled.DefaultColumnText>
                <span style={{ fontSize: '2rem' }}>€</span> 0.30
              </Styled.DefaultColumnText>
            </Styled.DefaultColumnTop>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow>
            <Styled.DefaultColumn left>
              <XCircle size={22} />
            </Styled.DefaultColumn>
            <Styled.MiddleColumn>
              <Styled.InformationContent>
                <img src={CheckIcon} width={22} height={22} />
                <Styled.MiddleColumnText>
                {t('priceTable.longTravels')}
                </Styled.MiddleColumnText>
              </Styled.InformationContent>
            </Styled.MiddleColumn>
            <Styled.DefaultColumn right>
              <XCircle size={22} />
            </Styled.DefaultColumn>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow>
            <Styled.DefaultColumn left>
              <Styled.DefaultColumnText>
                <span style={{ fontSize: '2rem' }}>€</span> 0.35
              </Styled.DefaultColumnText>
            </Styled.DefaultColumn>
            <Styled.MiddleColumn>
              <Styled.InformationContent>
                <Styled.DefaultColumnText>
                  <span style={{ fontSize: '2rem' }}>€</span> 0.90
                </Styled.DefaultColumnText>
                <Styled.MiddleColumnText>
                {t('priceTable.kmValueNigth')}
                </Styled.MiddleColumnText>
              </Styled.InformationContent>
            </Styled.MiddleColumn>
            <Styled.DefaultColumn right>
              <Styled.DefaultColumnText>
                <span style={{ fontSize: '2rem' }}>€</span> 0.35
              </Styled.DefaultColumnText>
            </Styled.DefaultColumn>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow>
            <Styled.DefaultColumn left>
              <XCircle size={22} />
            </Styled.DefaultColumn>
            <Styled.MiddleColumn>
              <Styled.InformationContent>
                <img src={CheckIcon} width={22} height={22} />
                <Styled.MiddleColumnText>{t('priceTable.tours')}</Styled.MiddleColumnText>
              </Styled.InformationContent>
            </Styled.MiddleColumn>
            <Styled.DefaultColumn right>
              <XCircle size={22} />
            </Styled.DefaultColumn>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow>
            <Styled.DefaultColumnBottom left>
              <Styled.DefaultColumnText>{t('priceTable.many')}</Styled.DefaultColumnText>
            </Styled.DefaultColumnBottom>
            <Styled.MiddleColumn>
              <Styled.InformationContent>
                <p>{t('priceTable.upperClass')}</p>
                <Styled.MiddleColumnText>{t('priceTable.targetAudience')}</Styled.MiddleColumnText>
              </Styled.InformationContent>
            </Styled.MiddleColumn>
            <Styled.DefaultColumnBottom right>
              <Styled.DefaultColumnText>{t('priceTable.many')}</Styled.DefaultColumnText>
            </Styled.DefaultColumnBottom>
          </Styled.PriceInformationRow>
          <Styled.PriceInformationRow edge>
            <div>
              <p></p>
            </div>
            <Styled.MiddleColumnBottom>
              <Styled.InformationContent>
                <Styled.BookingButton>{t('priceTable.bookNow')}</Styled.BookingButton>
              </Styled.InformationContent>
            </Styled.MiddleColumnBottom>
            <div>
              <p></p>
            </div>
          </Styled.PriceInformationRow>
        </Styled.SectionPriceInformationContentContainer>
      </Styled.SectionPriceInformationContainer>
    )
}