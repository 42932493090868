import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  rem,
  Checkbox
} from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import * as Styled from './styles'
import { ChevronRight, Copy, Pen, Trash2 } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import {
  columnAccount,
  columnBooking,
  columnDriver,
  columnPartnership
} from './grid-props'
import { toast } from 'react-toastify'
import { useClipboard } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'

const jobColors = {
  engineer: 'blue',
  manager: 'cyan',
  designer: 'pink'
}

export function DataTableFinancial({
  loading = false,
  data,
  setDelete,
  selection,
  setSelection,
  type
}) {
  const { t } = useTranslation()

  const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    )
  const toggleAll = () =>
    setSelection((current) =>
      current?.length === data?.length ? [] : data.map((item) => item.id)
    )

  const navigate = useNavigate()
  const clipboard = useClipboard({ timeout: 500 })

  const rows = data?.map((item) => {
    const selected = selection?.includes(item.id)

    const handleRowClick = (event) => {
      const isCheckbox = event.target.type === 'checkbox'
      const isActionIcon =
        event.target.closest('.action-icon') ||
        event.target.closest('.action-icon-svg')

      if (isCheckbox || isActionIcon) {
        return
      }

      if (type === 'drivers') {
        navigate(`driver/${item.driver.id}`)
      } else {
        navigate(`${item.id}`)
      }
    }

    const PaymentMethodMap = (paymentMethod) => {
      switch (paymentMethod) {
        case 'CREDIT_CARD':
          return 'Cartão de crédito'
        case 'MBWAY':
          return 'MB Way'
        case 'MBMULTI':
          return 'MB Multi'
        case 'CASH':
          return 'Dinheiro'
        default:
          return paymentMethod
      }
    }
    const PaymentStatus = (paymentStatus) => {
      switch (paymentStatus) {
        case 'PENDING':
          return 'Pendente'
        case 'ACCPETED':
          return 'Aceito'
        case 'APPROVED':
          return 'Aprovado'
        case 'REFUSED':
          return 'Recusado'
        default:
          return paymentStatus
      }
    }

    const formatCurrency = (currency) => {
      return Intl.NumberFormat('PT', {
        currency: 'EUR',
        style: 'currency'
      }).format(currency)
    }

    function formatDate(transferDate) {
      const data = new Date(transferDate)

      const opcoesFusoHorario = { timeZone: 'Europe/Lisbon' }

      data.setMinutes(
        data.getMinutes() +
          data.getTimezoneOffset() +
          (data.getTimezoneOffset() === 0 ? 0 : -60)
      )

      const dia = String(data.getDate()).padStart(2, '0')
      const mes = String(data.getMonth() + 1).padStart(2, '0')
      const ano = String(data.getFullYear()).slice(-2)
      const horas = String(
        data.toLocaleString('pt-PT', {
          ...opcoesFusoHorario,
          hour: 'numeric',
          hour12: false
        })
      ).padStart(2, '0')
      const minutos = String(data.getMinutes()).padStart(2, '0')

      return `${dia}/${mes}/${ano} - ${horas}:${minutos}`
    }

    const renderItems = () => {
      if (type === 'drivers') {
        return (
          <>
            <td>
              <Checkbox
                color="27C7FF" 
                checked={selection?.includes(item.id)}
                onChange={() => toggleRow(item.id)}
              />
            </td>
            <td>
              <Text fz="sm" fw={500}>
                {item.driver?.name}
              </Text>
            </td>
            <td>
              <Text fz="sm" fw={500}>
                {item.driver?.id}
              </Text>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {item?.vehicle?.category?.name}
              </Anchor>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {formatCurrency(item.totalPrice / 100)}
              </Anchor>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {formatCurrency(item.totalDriverCommission / 100)}
              </Anchor>
            </td>
            <td>
              <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
            </td>
            <td>
              <Group gap={0} justify="flex-end">
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  className="action-icon"
                  onClick={() => navigate(`${item.driver.id}`)}
                >
                  <ChevronRight width={24} height={24} color="#27c7ff" />
                </ActionIcon>
              </Group>
            </td>
          </>
        )
      } else if (type === 'partnership_transfers') {
        return (
          <>
            <td>
              <Checkbox
                color="27C7FF" 
                checked={selection?.includes(item.id)}
                onChange={() => toggleRow(item.id)}
              />
            </td>
            <td>
              <Text fz="sm" fw={500}>
                {item.driver?.name}
              </Text>
            </td>
            <td>
              <Text fz="sm">
                {item.id.length > 8 ? item.id.slice(0, 8) + '...' : item.id}{' '}
                <Copy
                  size="16"
                  style={{ cursor: 'pointer' }}
                  alt="Copy id"
                  onClick={(e) => {
                    e.stopPropagation()
                    try {
                      clipboard.copy(item.id)
                      toast.success(t('toast.copiedID'))
                    } catch {
                      toast.error(t('errorCopiedID'))
                    }
                  }}
                />
              </Text>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {item?.vehicle?.category?.name}
              </Anchor>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {formatCurrency(item.totalPrice / 100)}
              </Anchor>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {formatCurrency(item.totalDriverCommission / 100)}
              </Anchor>
            </td>
            <td>
              <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
            </td>
            <td>
              <Group gap={0} justify="flex-end">
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  className="action-icon"
                  onClick={() => navigate(`${item.id}`)}
                >
                  <ChevronRight width={24} height={24} color="#27c7ff" />
                </ActionIcon>
              </Group>
            </td>
          </>
        )
      } else {
        return (
          <>
            <td>
              <Checkbox
                color="27C7FF" 
                checked={selection?.includes(item.id)}
                onChange={() => toggleRow(item.id)}
              />
            </td>
            <td>
              <Text fz="sm">
                {item.id.length > 8 ? item.id.slice(0, 8) + '...' : item.id}{' '}
                <Copy
                  size="16"
                  style={{ cursor: 'pointer' }}
                  alt="Copy id"
                  onClick={(e) => {
                    e.stopPropagation()
                    try {
                      clipboard.copy(item.id)
                      toast.success(t('toast.copiedID'))
                    } catch {
                      toast.error(t('errorCopiedID'))
                    }
                  }}
                />
              </Text>
            </td>

            <td>
              <Text fz="sm">{formatDate(item.transferDate)}</Text>
            </td>
            <td>
              <Text fz="sm">{PaymentStatus(item.paymentStatus)}</Text>
            </td>

            <td>
              <Anchor component="button" size="sm">
                {item?.vehicle?.category?.name}
              </Anchor>
            </td>
            <td>
              <Anchor component="button" size="sm">
                {formatCurrency(item.totalPrice / 100)}
              </Anchor>
            </td>
            <td>
              <Text fz="sm">{PaymentMethodMap(item.paymentMethod)}</Text>
            </td>
            <td>
              <Group gap={0} justify="flex-end">
                <ActionIcon
                  variant="subtle"
                  color="gray"
                  className="action-icon"
                  onClick={() => navigate(`${item.id}`)}
                >
                  <ChevronRight width={24} height={24} color="#27c7ff" />
                </ActionIcon>
              </Group>
            </td>
          </>
        )
      }
    }

    return (
      <tr
        key={item.id}
        className={selected ? 'selected' : ''}
        style={{ cursor: 'pointer' }}
        onClick={(event) => handleRowClick(event)}
      >
        {renderItems()}
      </tr>
    )
  })

  const dataTableRole = data?.some((item) => item.role === 'USER')

  return (
    <Styled.Container>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>
              <Checkbox
                onChange={toggleAll}
                checked={selection?.length === data?.length}
                indeterminate={
                  selection?.length > 0 && selection?.length !== data?.length
                }
                color="27C7FF" 
              />
            </th>
            {(type === 'drivers'
              ? columnDriver
              : type === 'partnership_transfers'
              ? columnPartnership
              : columnBooking
            ).map((item, index) => (
              <th key={index}>{item.title}</th>
            ))}
          </tr>
        </thead>
        {loading && <span>Carregando...</span>}
        <tbody>{rows}</tbody>
      </Table>
    </Styled.Container>
  )
}
