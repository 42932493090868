import * as Styled from './styles'

import passenger from '../../../../../Fleet/imgs/passenger.svg'
import briefcase from '../../../../../Fleet/imgs/briefcase.svg'
import wifi from '../../../../../Fleet/imgs/wifi.svg'
import { useTransferContext } from '../../../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import useAPI from '../../../../../../hooks/useAPI'
import { useState } from 'react'

export const CarCard = ({ car = [], onSelectCar, setShowCars }) => {
  const { going, carSelected, calculateRoute } = useTransferContext()
  const [distance, setDistance] = useState(1)

  const api = useAPI()

  useEffect(() => {
    async function call() {
      const results = await api.easyTransfer.api.directionClient.getRouteDetails({
        origin: going.from,
        destination: going.to,
        travelMode: 'DRIVING',
        passengerStops: going.passengerStops
      })
      const distance = api.easyTransfer.api.directionClient.extractDistanceFromLegsToKm(results)
      setDistance(distance)
    }
    void call()
    
  })

  const distanceValue = parseFloat(distance)
  const { t } = useTranslation()

  const carThatFitPassengers = car.filter((car) => {
    return car.passengers >= going.allPassangers
  })

  return (
    <Styled.Container>
      {carThatFitPassengers.map((car) => (
        <Styled.CardContainer key={car.title}>
          <Styled.CarContainer>
            <Styled.ImageCar src={car.img} />
            <div>
              <Styled.Title>{car.title}</Styled.Title>
              <Styled.OptionsContainer>
                {car.passengers && (
                  <Styled.Passenger>
                    <img src={passenger} />
                    <p>{car.passengers}</p>
                  </Styled.Passenger>
                )}
                {car.briefcase && (
                  <Styled.Briefcase>
                    <img src={briefcase} />
                    <p>{car.briefcase}</p>
                  </Styled.Briefcase>
                )}
                {car.wifi && <img src={wifi} />}
              </Styled.OptionsContainer>
            </div>
          </Styled.CarContainer>
          <Styled.PriceContainer>
            <h1>
              € {(car.price * distanceValue).toFixed(2)}
            </h1>
            <p>IVA incl.</p>
            <Styled.ChoiceButton
              onClick={() => {
                onSelectCar(car)
                setShowCars(false)
                console.log(car, '!!!')
              }}
            >
              {t('select')}
            </Styled.ChoiceButton>
          </Styled.PriceContainer>
        </Styled.CardContainer>
      ))}
    </Styled.Container>
  )
}
