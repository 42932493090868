import { useEffect, useRef, useState } from 'react'
import { LoadingOverlay, Pagination, Switch } from '@mantine/core'
import { DataTableLowCost } from '../../../../components/DataTableLowCost'

import * as Styled from './styles'
import { useFetchBooking } from './useFetchBooking'
import { SwitchOptions } from './components/SwitchOptions'
import { SearchInput } from './components/SearchInput'

export const LowCostPage = () => {
  const {
    bookings,
    loading,
    totalPages,
    onSearch,
    onChangePage,
    currentPage,
    changeBookingType,
    createLowCosts,
    disableLowCosts
  } = useFetchBooking()

  const switchRef = useRef()

  const [selectedBookingIds, setSelectedBookingIds] = useState([])
  const [defaultLowCostChecked, setDefaultLowCostChecked] = useState(false)

  const clearSelectedBookings = () => setSelectedBookingIds([])

  async function turnIntoLowCost() {
    const resoponse = await createLowCosts(selectedBookingIds)

    if (resoponse.status === 200) {
      clearSelectedBookings()
      switchRef.current.next()
    }
  }

  async function turnIntoTransfer() {
    const resoponse = await disableLowCosts(selectedBookingIds)

    if (resoponse.status === 200) {
      clearSelectedBookings()
      switchRef.current.previous()
    }
  }

  function toggleSwitch(activated) {
    if (activated) return turnIntoLowCost()

    turnIntoTransfer()
  }

  return (
    <>
      <LoadingOverlay overlayOpacity={0.5} visible={loading} />
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>Low Cost</Styled.Title>
          <LowCostToggle
            defaultChecked={defaultLowCostChecked}
            disabled={selectedBookingIds.length === 0}
            onChange={toggleSwitch}
          />
        </Styled.Header>

        <Styled.FiltersWrapper>
          <SwitchOptions
            ref={switchRef}
            onChange={({ value }) => {
              changeBookingType(value)
              clearSelectedBookings()
              setDefaultLowCostChecked((old) => !old)
            }}
          />

          <div style={{ display: 'flex', gap: 16 }}>
            <SearchInput
              propName="places"
              onChange={onSearch}
              placeholder="Pesquisar por zona"
            />
            <SearchInput
              propName="id"
              onChange={onSearch}
              placeholder="Pesquisar por id"
            />
          </div>
        </Styled.FiltersWrapper>
        <Styled.Content>
          <DataTableLowCost
            data={bookings ?? []}
            selection={selectedBookingIds}
            setSelection={setSelectedBookingIds}
          />
          <Pagination
            className="pagination"
            size="xl"
            total={totalPages}
            radius="md"
            color="27C7FF" 
            withControls={true}
            value={currentPage}
            onChange={(value) => onChangePage(value - 1, 'page')}
          />
        </Styled.Content>
      </Styled.Container>
    </>
  )
}

function LowCostToggle({ defaultChecked, disabled, onChange }) {
  const cachedDefaultChecked = useRef(defaultChecked)
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (defaultChecked != cachedDefaultChecked.current) {
      setValue(defaultChecked)
      cachedDefaultChecked.current = defaultChecked
    }
  }, [defaultChecked])

  function toggle({ currentTarget }) {
    setValue(currentTarget.checked)
    onChange(currentTarget.checked)
  }

  return (
    <Switch
      defaultChecked={defaultChecked}
      checked={value}
      onChange={toggle}
      size="xl"
      color="lime"
      label="Low Cost"
      labelPosition="left"
      onLabel="Ativo"
      disabled={disabled}
    />
  )
}
