import styled from 'styled-components'

export const Container = styled.div`
  margin: 370px 0 255px 0;
  @media only screen and (max-width: 1024px) {
    margin: 150px 0;

  }
`
export const MobileTableContainer = styled.div`
  width: 100%;

  display: flex;
  gap: 2%;

  display: none;

  svg {
    width: 100%;
  }

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

export const OrangeHorizontalContainer = styled.div`
  background: #27c7ff;
  width: 70%;
  height: 464px;
  border-radius: 32px;
  margin: 0 auto;
  top: 200px;
  padding: 16px;

  display: flex;
  gap: 2%;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const OrangeVerticalContainer = styled.div`
  background: #27c7ff;
  width: 20%;
  height: 685px;
  box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  position: relative;
  bottom: 41%;
  padding: 6px 0;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

export const OrangeVerticalWrapper = styled.div`
  width: 204px;
  height: 432px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const EasyTransferLogo = styled.img`
  width: 136.37px;
  height: 48px;
  margin-bottom: 20px;
  margin-top: 6px;
`

export const Price = styled.p`
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  color: #fff;
  margin-bottom: 30px;
`

export const OrangeContainerSeparator = styled.div`
  /* height: 0.7px; */
  width: 70%;
  /* background-color: rgba(255, 255, 255, 0.3); */

  border: 0.2px solid rgba(255, 255, 255, 0.3);
`

export const BookNowButton = styled.a`
  text-decoration: none;
  background-color: #fff;
  width: 139px;
  height: 41px;
  box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
  border-radius: 8px;
  border: none;
  padding: 12px 24px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #27c7ff;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: ease-in-out 300ms;

  :hover {
    background-color: #27c7ff;
    color: white;
    border: solid 1px #fff;
    span {
      color: white;
    }
  }
`

export const WhiteContainer = styled.div`
  width: 204px;
  height: 432px;
  background: #ffffff;
  border-radius: 24px;
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

export const WhiteContainerSeparator = styled.div`
  /* background: #b3b3b344; */
  /* height: 0.7px; */
  width: 90%;

  border: 0.2px solid #b3b3b344;
`

export const TextWrapper = styled.div`
  padding: 4px 32px;
  width: 38%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const Text = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

export const TextWrapperSeparator = styled.div`
  background: rgba(255, 255, 255, 0.3);
  /* height: 0.2px; */
  width: 100%;

  border: 0.7px solid rgba(255, 255, 255, 0.3);
  opacity: 0.7;
`

export const ColumnWrapper = styled.div`
  // width: 204px;
  height: 580px;
  position: relative;
  bottom: 34%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const PriceButton = styled.div`
  background: #9ad7ed;
  color: #005775;
  border-radius: 8px;
  width: 106px;
  height: 46px;
  padding: 12px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
`
// New Mobile Table

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;

  display: none;

  margin: 0px 156px;

  @media only screen and (max-width: 1024px) {
    display: block;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    
  }

  @media only screen and (max-width: 768px) {
    margin: 0px 16px;
    display: flex;
  }
`

export const MobileTableHeader = styled.div`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  /* border: 1px solid red; */
`

export const MobileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  @media (max-width: 430px) {
    svg {
      width: 40px;
      height: 13.886px;
    }
  }
`

export const EasyTransferContainer = styled.div`
  display: flex;
  padding: 16px 32px;

  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 32px;
  background: var(--yellow-3-support, #1D95BF);

  margin-bottom: 24px;

  h1 {
    color: #fff;
    /* font-weight: 600; */
    font-size: 24px;
    line-height: 22px;
  }

  @media (max-width: 430px) {
    padding: 8px 16px;
    font-size: 12px;
    line-height: normal;

    h1 {
      font-size: 18px;
      text-align: center;
    }
  }
`

export const MobileTableContentsContainer = styled.div`
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  border-radius: 10px;
  background: #27c7ff;

  width: 100%;
`

export const MobileTableContents = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-evenly;

  padding: 12px 36px;

  border-radius: 8px;
  background: #fff;

  width: 100%;

  #frame-vector {
    margin-left: 32px;
    margin-right: 32px;
  }

  margin-bottom: 24px;
`

export const MobileTableChecks = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MobileTableContentHeader = styled.h1`
  color: #fff;
  text-align: center;
`
