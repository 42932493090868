import { useRef, useState } from 'react'
import { CouponCard } from '../components/CouponCard'
import { FilterPopup } from '../components/FilterPopup'
import { Input, Pagination } from '@mantine/core'
import { ChevronDown, Search } from 'lucide-react'
import debounce from '../../../../../utils/debounce'
import useClickOutside from '../../../../../hooks/useClickOutside'
import fetchCoupons from '../../../../../services/coupon'
import * as Styled from './styles'
import { CreateCouponModal } from '../components/CreateCouponModal'
import { useEffect } from 'react'
import { ClipLoader } from 'react-spinners'

const PAGE_SIZE = 50

export const Coupons = () => {
  const [search, setSearch] = useState('')
  const [activeFilter, setActiveFilter] = useState(undefined)
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
  const [createCouponModal, setCreateCouponModal] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)

  const filterButtonRef = useRef(null)

  const domNode = useClickOutside(
    () => setIsFilterPopupOpen(false),
    filterButtonRef,
    []
  )

  const handleChange = (value) => {
    debounce(() => setSearch(value), 500)
  }

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const params = {
        size: PAGE_SIZE,
        coupon: search || undefined,
        page: currentPage,
        deleted: false,
        ...(activeFilter && { active: activeFilter === 'active' })
      }
      try {
        const response = await fetchCoupons.getAll(params)
        const total = Math.ceil(response.data.totalElements / PAGE_SIZE)
        setTotalPages(total)
        setCoupons(response.data.content || [])
        setLoading(false)
      } catch (err) {
        console.log(err)
        setCoupons([])
        setLoading(false)
        setCurrentPage(0)
        setTotalPages(1)
      }
    })()
  }, [search, currentPage, activeFilter])

  return (
    <>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>Cupons</Styled.Title>
          <Styled.ConfirmButton onClick={() => setCreateCouponModal(true)}>
            NOVO
          </Styled.ConfirmButton>
        </Styled.Header>
        <Styled.FiltersWrapper>
          <Input.Wrapper id="filters">
            <Input
              id="input-demo"
              placeholder="Pesquisar"
              size={'lg'}
              rightSection={<Search width={24} height={24} color="#BFBFBF" />}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Input.Wrapper>
          <div style={{ position: 'relative' }}>
            <Input
              id="select-filter"
              component="button"
              ref={filterButtonRef}
              onClick={() => setIsFilterPopupOpen((oldState) => !oldState)}
              rightSection={
                <ChevronDown
                  color="#27c7ff"
                  style={{
                    transform: isFilterPopupOpen
                      ? 'rotate(180deg)'
                      : 'rotate(0deg)',
                    transition: 'transform 0.2s ease-in-out'
                  }}
                />
              }
              pointer
              mt="md"
            >
              {activeFilter
                ? activeFilter === 'active'
                  ? 'Ativo'
                  : 'Inativo'
                : 'Filtro'}
            </Input>
            {isFilterPopupOpen && (
              <FilterPopup
                ref={domNode}
                onClose={() => setIsFilterPopupOpen(false)}
                initialFilter={activeFilter}
                clearFilter={() => {
                  setActiveFilter(undefined)
                  setIsFilterPopupOpen(false)
                }}
                onSuccess={(filter) => {
                  setActiveFilter(filter)
                  setIsFilterPopupOpen(false)
                }}
              />
            )}
          </div>
        </Styled.FiltersWrapper>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '40rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ClipLoader size={50} color="#27c7ff" />
          </div>
        ) : (
          <Styled.Content>
            <div>
              {coupons.filter((coupon) => !coupon.deleted).length === 0 && (
                <h1 style={{ color: '#808080', fontSize: '1.8rem' }}>
                  Nenhum cupom encontrado!
                </h1>
              )}
              {coupons
                .filter((coupon) => !coupon.deleted)
                .map((coupon) => (
                  <CouponCard key={coupon.id} couponItem={coupon} />
                ))}
            </div>

            {totalPages > 1 && (
              <Pagination
                className="pagination"
                size="xl"
                total={totalPages}
                radius="md"
                color="27C7FF" 
                withControls={false}
                value={currentPage + 1}
                onChange={(value) => setCurrentPage(value - 1)}
              />
            )}
          </Styled.Content>
        )}
      </Styled.Container>
      {createCouponModal && (
        <CreateCouponModal
          onClose={() => setCreateCouponModal(false)}
          onSuccess={(couponData) => {
            setCoupons((oldState) => [...oldState, couponData])
            setCreateCouponModal(false)
          }}
        />
      )}
    </>
  )
}
