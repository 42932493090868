import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Card } from './components/Card'
import { fleets } from './fleets'
import { useTranslation } from 'react-i18next'
import { Carousel } from '@mantine/carousel'
import placeholder1 from './imgs/carousel/carro_01.webp'
import placeholder3 from './imgs/carousel/carro_02.webp'
import placeholder2 from './imgs/carousel/0094e6_00dec48ac4854f038820553b05e401d1~mv2.webp'
import placeholder5 from './imgs/carousel/mercedes-van-e-220-edit.webp'
import placeholder4 from './imgs/carousel/mercedes-e-class-220-interiors-seats-2.webp'
import placeholder6 from './imgs/carousel/mercedes-e-class-220-interiors.webp'
import placeholder7 from './imgs/carousel/mercedes-eqe-electric-2.webp'
import placeholder8 from './imgs/carousel/mercedes-eqe-electric-1.png'
import placeholder9 from './imgs/carousel/mercedes-eqe-electric-3.webp'
import placeholder10 from './imgs/carousel/mercedes-eqs-1.webp'
import placeholder11 from './imgs/carousel/mercedes-eqs-2.webp'
import placeholder12 from './imgs/carousel/mercedes-eqs-3.webp'
import placeholder13 from './imgs/carousel/mercedes-eqv-1.webp'
import placeholder14 from './imgs/carousel/mercedes-eqv-2.webp'
import placeholder15 from './imgs/carousel/mercedes-eqv-3.webp'
import placeholder16 from './imgs/carousel/mercedes-maybach-1.webp'
import placeholder17 from './imgs/carousel/mercedes-maybach-2.webp'
import placeholder18 from './imgs/carousel/mercedes-maybach-3.webp'
import placeholder19 from './imgs/carousel/mercedes-s-class-1.webp'
import placeholder20 from './imgs/carousel/mercedes-s-class-2.webp'
import placeholder21 from './imgs/carousel/mercedes-s-class-3.webp'
import placeholder22 from './imgs/carousel/minibus-sprinter-519-1.webp'
import placeholder23 from './imgs/carousel/minibus-sprinter-519-2.webp'
import placeholder24 from './imgs/carousel/minibus-sprinter-519-3.webp'
import { useEffect, useRef, useState } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { ChevronRight } from 'lucide-react'
import { ChevronLeft } from 'lucide-react'
import { useMediaQuery } from '@mantine/hooks';
export const Fleet = () => {
  const { t } = useTranslation()
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Styled.Container>
      <Navbar />
      <Styled.ContentContainer>
        <Styled.CarouselContainer>
          <Carousel height={600} withControls
            controlSize={48}
            loop
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}
            slideSize={isMobile ? '100%' : '33.333333%'}
            slideGap="lg"
            nextControlIcon={<ChevronRight size={18} />}
            previousControlIcon={<ChevronLeft size={18} />}
            slidesToScroll={isMobile ? 1 : 3}
          >
            {[placeholder1, placeholder2, placeholder3, placeholder4, placeholder5, placeholder6, placeholder7,placeholder8, placeholder9, placeholder10, placeholder11, placeholder12, placeholder13, placeholder14,placeholder15, placeholder16, placeholder17, placeholder18, placeholder19, placeholder20, placeholder21, placeholder22, placeholder23, placeholder24].map((image, index) => {
              return (
              <Carousel.Slide key={index}>
                <Styled.CarouselImgWrapper>
                  <Styled.CarouselImg src={image} />
                  <Styled.GradientOverlay />
                </Styled.CarouselImgWrapper>
              </Carousel.Slide>)
            })}
          </Carousel>
        </Styled.CarouselContainer>
        <Styled.Title>{t('fleet.exploreFleet')}</Styled.Title>
        <div>
          {fleets.map((fleets) => {
            const category = fleets[0].category
            return (
              <>
                <Styled.CategoryContainer>
                  <Styled.CategoryTextContainer>
                    <Styled.CategoryText>
                      {category}
                    </Styled.CategoryText>
                    <Styled.CategoryLine />
                  </Styled.CategoryTextContainer>
                  <Card fleets={fleets} />
                </Styled.CategoryContainer>
              </>
            )
          })}
        </div>
      </Styled.ContentContainer>
      <Footer />
    </Styled.Container>
  )
}
