class DirectionClient {
    
    constructor() {
        // eslint-disable-next-line no-undef
        this.directionsService = new google.maps.DirectionsService();
    }

    async getRouteDetails({ origin, destination, travelMode, passengerStops }) {
        try {

            const waypoints = passengerStops?.map((stop) => ({ location: stop.from })) || [];
            console.log('eita', origin, destination, travelMode, waypoints)
            const results = await this.directionsService.route({
                origin,
                destination,
                // eslint-disable-next-line no-undef
                travelMode: travelMode || google.maps.TravelMode.DRIVING,
                waypoints
            });

            return results;
        } catch (error) {
          
            console.error("Error calculating distance:", error);
            throw error;
        }
    }

    extractDistanceFromLegsToKm = (routeDetails) => {
        const legs = routeDetails?.routes[0]?.legs
        const totalDistanceInMeters = legs.reduce(
            (acc, curr) => acc + curr?.distance?.value,
            0
        )

        const totalDistanceInKm = Math.round(totalDistanceInMeters / 1000)
        return Intl.NumberFormat('pt-BR', {
            style: 'unit',
            unit: 'kilometer',
            unitDisplay: 'short'
        }).format(totalDistanceInKm)
    }
}

export default DirectionClient;