import axios from 'axios';

class APIClient {
  constructor(baseURL, headers = {}) {
    this.client = axios.create({
      baseURL,
      headers,
    });
  }

  // Método GET
  async get(endpoint, params = {}, config = {}) {
    try {
      const response = await this.client.get(endpoint, {
        params,
        ...config,
      });
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Método POST
  async post(endpoint, data = {}, config = {}) {
    try {
      const response = await this.client.post(endpoint, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Método PUT
  async put(endpoint, data = {}, config = {}) {
    try {
      const response = await this.client.put(endpoint, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Método DELETE
  async delete(endpoint, config = {}) {
    try {
      const response = await this.client.delete(endpoint, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Método PATCH
  async patch(endpoint, data = {}, config = {}) {
    try {
      const response = await this.client.patch(endpoint, data, config);
      return response.data;
    } catch (error) {
      this.handleError(error);
    }
  }

  // Método para tratar erros
  handleError(error) {
    if (error.response) {
      console.error('Response error:', error.response.status, error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
}

export default APIClient
