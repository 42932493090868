import { useEffect } from 'react'
import { DataTable } from '../../../../components/DataTable'
import * as Styled from './styles'
import fetchAccount from '../../../../services/account'
import fetchCompany from '../../../../services/company'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Pagination } from '@mantine/core'
import { ChevronDown, Search } from 'lucide-react'
import debounce from '../../../../utils/debounce'
import { RejectModal } from './components/RejectModal'
import { toast } from 'react-toastify'
import fetchCarCategory from '../../../../services/carCategory'
import { ClipLoader } from 'react-spinners'
import { RegisterModal } from './components/RegisterModal'
import { SwitchOptions } from './components/SwitchOptions'
import { useTranslation } from 'react-i18next'

export const Fleet = () => {
  const [data, setData] = useState()
  const [dataEnterprise, setDataEnterprise] = useState()
  const [total, setTotal] = useState()
  const [search, setSearch] = useState({
    name: '',
    orderBy: 'name',
    size: 10,
    page: 0,
    direction: 'asc'
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)
  const [refreshModal, setRefreshModal] = useState(false)
  const [selection, setSelection] = useState(['1'])
  const [categories, setCategories] = useState()
  const [loading, setLoading] = useState(true)
  const [switchOption, setSwitchOption] = useState('Motoristas')

  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleChange = (value, name) => {
    if (name === 'size' || name === 'page') {
      setSearch((prevSearch) => ({ ...prevSearch, [name]: value }))
    } else {
      debounce(() => setSearch({ ...search, [name]: value }), 500)
    }
  }

  useEffect(() => {
    const params = Object.entries(search).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key] = value
      }
      return acc
    }, {})
    params.role = 'USER'

    fetchAccount
      .getAllAccount(params)
      .then((res) => {
        if (res) {
          setData(res.data.content)
          setTotal(res.data.totalPages)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading(false)
      })
  }, [search, refreshModal])
  useEffect(() => {
    const params = Object.entries(search).reduce((acc, [key, value]) => {
      if (value !== undefined && value !== null && value !== '') {
        acc[key] = value
      }
      return acc
    }, {})
    params.role = 'USER'

    fetchCompany
      .getAllCompany(params)
      .then((res) => {
        if (res) {
          setDataEnterprise(res.data.content)
          setTotal(res.data.totalPages)
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoading(false)
      })
  }, [search, refreshModal])

  useEffect(() => {
    fetchCarCategory.getAll({ deleted: false }).then((res) => {
      setCategories(res?.data?.content)
    })
  }, [])

  const handleDeleteAccount = () => {
    ;(switchOption === 'Motoristas'
      ? fetchAccount.delete(deleteModal.id)
      : fetchCompany.delete(deleteModal.id)
    )
      .then(() => {
        toast.success(t('toast.successDeleteAccount'))
        setDeleteModal(false)
        setRefreshModal(!refreshModal)
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(t('toast.errorDeleteAccount'))
      })
  }

  const handleRegisterForm = (data) => {
    if (data === 'autonomo') {
      navigate('driver/registrar')
    } else {
      navigate('empresa/registrar')
    }
  }

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>Frota</Styled.Title>
        <Styled.ConfirmButton onClick={() => setRegisterModal(true)}>
          Registrar
        </Styled.ConfirmButton>
      </Styled.Header>
      <Styled.FiltersWrapper>
        <Input.Wrapper id="filters">
          <Input
            id="input-demo"
            placeholder="Insira o nome"
            size={'lg'}
            rightSection={<Search width={24} height={24} color="#BFBFBF" />}
            onChange={(e) => handleChange(e.target.value, 'name')}
          />
        </Input.Wrapper>
        <Input
          id="select-filter"
          component="select"
          rightSection={<ChevronDown />}
          pointer
          mt="md"
          onChange={(e) =>
            setSearch({
              ...search,
              deleted: e.target.value
            })
          }
        >
          <option value="">Status</option>
          <option value="false">Ativo</option>
          <option value="true">Inativo</option>
        </Input>
        {switchOption === 'Motoristas' && (
          <Input
            id="select-filter"
            component="select"
            rightSection={<ChevronDown />}
            pointer
            mt="md"
            onChange={(e) =>
              setSearch({
                ...search,
                vehicleCategoryName: e.target.value
              })
            }
          >
            <option value="">Categoria</option>
            {categories?.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </Input>
        )}
      </Styled.FiltersWrapper>
      <SwitchOptions
        // ref={switchRef}
        onChange={(value) => {
          setSwitchOption(value.option)
        }}
      />
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '40rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.Content>
          {switchOption === 'Motoristas' && (
            <>
              <DataTable
                data={data}
                setDelete={setDeleteModal}
                selection={selection}
                setSelection={setSelection}
                type={'driver'}
              />
              <Pagination
                className="pagination"
                size="xl"
                total={total}
                radius="md"
                color="27C7FF" 
                withControls={false}
                value={search?.page + 1}
                onChange={(value) => handleChange(value - 1, 'page')}
              />
            </>
          )}
          {switchOption === 'Empresas' && (
            <>
              <DataTable
                data={dataEnterprise}
                setDelete={setDeleteModal}
                selection={selection}
                setSelection={setSelection}
                partnership
                type={'enterprise'}
              />
              <Pagination
                className="pagination"
                size="xl"
                total={total}
                radius="md"
                color="27C7FF" 
                withControls={false}
                value={search?.page + 1}
                onChange={(value) => handleChange(value - 1, 'page')}
              />
            </>
          )}
        </Styled.Content>
      )}

      <RegisterModal
        opened={registerModal}
        onClose={() => setRegisterModal(false)}
        onSuccess={handleRegisterForm}
      />
      <RejectModal
        opened={deleteModal}
        onClose={() => setDeleteModal(false)}
        onSuccess={handleDeleteAccount}
      />
    </Styled.Container>
  )
}
