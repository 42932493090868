import { useMemo, useCallback, useState } from "react"
import APIContext from "./APIContext"
import EasyTransferClient from "../../services/easyTransfer/EasyTransferClient"
import { useEffect } from "react"
import { useJsApiLoader } from '@react-google-maps/api'
import { libraries } from "./libraries"

function APIContextProvider({ children }) {

    let googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    if (!googleMapsApiKey) {
        googleMapsApiKey = 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg'
    }

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey,
        libraries: libraries
    })

    const [easyTransferClient, setEasyTransferClient] = useState(null)

    useEffect(() => {
        if (isLoaded) {
            setEasyTransferClient(new EasyTransferClient())
        }
    }, [isLoaded])

    const setEasyTransferClearingCurrent = useCallback((newClient) => {
        setEasyTransferClient((currentClient) => {
            currentClient.clear()
            return newClient
        })
    }, [])


    const api = useMemo(() => ({
        easyTransfer: easyTransferClient,
        setEasyTransfer: setEasyTransferClearingCurrent,
        isLoading: !isLoaded
    }), [easyTransferClient, setEasyTransferClearingCurrent])

    if (!isLoaded || !easyTransferClient) {
        return null
    }
    return <APIContext.Provider value={api}>{children}</APIContext.Provider>
}

export default APIContextProvider