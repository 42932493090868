import dayjs from 'dayjs'
import { Select } from '@mantine/core'
import * as Styled from './styles'
export const HourSelect = ({ value, onChange }) => {
    const generateTimeOptions = () => {
        const times = []
        const start = dayjs().startOf('day').hour(0).minute(0) // Início do dia
        const end = dayjs().endOf('day').hour(23).minute(59) // Final do dia

        let currentTime = start
        while (currentTime.isBefore(end)) {
            times.push(currentTime.format('HH:mm'))
            currentTime = currentTime.add(15, 'minute') // Incrementa 15 minutos
        }
        return times
    }

    const timeOptions = generateTimeOptions()

    return (
        <Styled.Container>
            <Select
                value={value}
                onChange={onChange}
                data={timeOptions}

                placeholder="Selecione o horário"
                styles={{
                    item: {
                        '&[data-hovered]': {
                            backgroundColor: '#27c7ff',
                            color: '#ffffff'
                        },
                        '&[data-selected]': {
                            backgroundColor: '#27c7ff',
                        },
                    },
                    wrapper: {
                        height: '100%',
                    }
                }}
                radius="md"
                variant='filled'
                size="lg"
            />
        </Styled.Container>

    )
}