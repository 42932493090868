import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  /* justify-content: space-between; */
  margin-bottom: 2rem;
  flex-wrap: wrap;

  gap: 2.4rem;

  @media (max-width: 1100px) {
    margin: 0 64px 64px;
  }

  @media (max-width: 875px) {
    margin: 0 0 64px;
  }

  @media (max-width: 460px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 16px;
  }

  > div {
    display: flex;

    flex-wrap: wrap;
    justify-content: center;

    gap: 24px;

    @media (max-width: 768px) {
      gap: 16px;
    }

    /* @media (max-width: 630px) {
      column-gap: 8px;
    } */
  }
`
