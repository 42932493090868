import styled, { css } from 'styled-components'

import { Button as UIButton } from '../../../../components/Button'
import { Calendar as UICalendar } from '../../../../components/Calendar'
import {
  Card as UICard,
  CardContent as UICardContent
} from '../../../../components/Card'
import { Input as UIInput } from '../../../../components/Input'
import { Label as UILabel } from '../../../../components/Label'
import {
  Popover as UIPopover,
  PopoverContent as UIPopoverContent,
  PopoverTrigger as UIPopoverTrigger
} from '../../../../components/Popover'
import { Textarea as UITextarea } from '../../../../components/Textarea'
import {
  ToggleGroup as UIToggleGroup,
  ToggleGroupItem as UIToggleGroupItem
} from '../../../../components/ToggleGroup'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > h2 {
    text-align: center;
    font-size: 3rem; /* 30px */
    line-height: 3.6rem; /* 36px */
    font-weight: bold;
    letter-spacing: -0.025em;
  }

  > form {
    border-radius: 0.8rem;
    border: 0.1rem solid #27272a !important;
    padding: 2.4rem;
    padding-bottom: 4.8rem;

    > * + * {
      margin-top: 2rem;
    }

    > :nth-child(1) {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 2.4rem;

      > div {
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.8rem;

        > div {
          margin-bottom: auto;

          p {
            font-size: 1.4rem;
            line-height: 2rem;
            color: #f87171;
          }

          * + * {
            margin-top: 0.4rem;
          }
        }
      }

      @media (min-width: 768px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    > :nth-child(2) {
      > * + * {
        margin-top: 1.6rem;
      }

      > div {
        margin-bottom: auto;

        > * + * {
          margin-top: 0.4rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #f87171;
        }
      }
    }

    > :nth-child(3) {
      > * + * {
        margin-top: 1.6rem;
      }

      > div {
        margin-bottom: auto;

        > * + * {
          margin-top: 0.4rem;
        }

        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #f87171;
        }
      }
    }

    > :nth-child(4) {
      display: flex;
      width: 100%;
      justify-content: center;
    }
  }
`

export const Button = styled(UIButton)`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  background-color: #FBB03B;
  border-radius: 1.6rem;
  padding: 1.2rem 2.8rem;
  color: #18181b;

  :hover {
    background-color: #FBB03B;
    opacity: 0.9;
  }
`

export const PopoverButton = styled(UIButton)`
  ${({ notValue }) => css`
    width: 100%;
    justify-content: flex-start;
    border-color: #52525b;
    background-color: #27272a;
    text-align: left;
    font-weight: 400;
    color: #f3f4f6;

    span {
      color: #6b7280;
    }

    :hover {
      background-color: #27272a;
      color: #f3f4f6;
      opacity: 0.8;
    }

    ${notValue &&
    css`
      color: hsl(215.4 16.3% 46.9%);
    `}
  `}
`

export const Calendar = styled(UICalendar)`
  border-radius: 0.4rem;
  border-color: #27272a;
  background-color: #09090b;
  color: #e5e7eb;
`

export const Input = styled(UIInput)`
  border-color: #52525b;
  background-color: #27272a;
  color: #f3f4f6;

  ::placeholder {
    color: #6b7280;
  }
`

export const Label = styled(UILabel)`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    color: #FBB03B;
  }
`

export const Popover = styled(UIPopover)``

export const PopoverContent = styled(UIPopoverContent)`
  width: auto;
  border-color: #27272a;
  padding: 0;
`

export const PopoverTrigger = styled(UIPopoverTrigger)``

export const Textarea = styled(UITextarea)`
  min-height: 9.6rem;
  resize: none;
  border: 0.1rem solid #52525b;
  background-color: #27272a;
  color: #ffffff;

  ::placeholder {
    color: #6b7280;
  }
`

export const ToggleGroup = styled(UIToggleGroup)`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: start;
  gap: 0.8rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`

export const ToggleGroupItem = styled(UIToggleGroupItem)`
  height: 11.2rem;

  @media (min-width: 640px) {
    height: 20.8rem;
  }
`

export const Card = styled(UICard)`
  flex: 1 1 0%;
  box-shadow: none;

  :hover {
    cursor: pointer;
  }
`

export const CardContent = styled(UICardContent)`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding: 1.6rem 0.8rem;

  img {
    max-height: 16rem;
    max-width: 22.4rem;
    height: 100%;
    width: 100%;

    @media (min-width: 1024px) {
      max-height: 11.2rem;
      max-width: 16rem;
      width: 100%;
      height: 100%;
    }
  }
`

export const ToggleLabel = styled(UILabel)`
  color: #e5e7eb;
`
