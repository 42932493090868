import {
  Group,
  Image,
  Menu,
  UnstyledButton,
  createStyles,
  rem
} from '@mantine/core'
import { Link } from 'react-router-dom'
import * as Styled from './styles'
import { useState } from 'react'
import portugal from './images/portugal.svg'
import alemanha from './images/germany.svg'
import estadosUnidos from './images/united-states-of-america.svg'
import franca from './images/france.svg'
import nederland from './images/netherlands.svg'
import helpIcon from './images/helpIcon.svg'

import iconChevronDown from './images/IconChevronDown.svg'
import darkEasyTransferLogo from './images/EasyTransfer - logo black.svg'

import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { SidebarToggle } from './components/SidebarToggle'
import { Header } from '../../pages/Checkout/Header'

const data = [
  { title: 'Portuguese', image: portugal, language: 'pt', country: 'Portugal' },
  { title: 'Dutch', image: nederland, language: 'nl', country: 'Netherlands' },
  { title: 'Deutsch', image: alemanha, language: 'de', country: 'Germany' },
  {
    title: 'English',
    image: estadosUnidos,
    language: 'en',
    country: 'United States'
  },
  { title: 'France', image: franca, language: 'fr', country: 'France' }
]

const useStyles = createStyles((theme, { opened }) => ({
  control: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'center',
    alignItems: 'center',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,
    border: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 6]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0]
    }
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)'
  },
  dropdown: {
    width: '15rem !important',
    zIndex: '999999999 !important'
  },
  darkDropdown: {
    width: '15rem !important',
    backgroundColor: 'black',
    border: '1px solid #1f1e1e',
    zIndex: '999999999 !important',
    '[data-hovered]': {
      backgroundColor: '#0D0D0D'
    }
  }
}))

export const NavbarCheckout = ({ dark = false, checkout = false }) => {
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened })
  const [selected, setSelected] = useState(data[0])
  const [language, setLanguage] = useState()
  console.log(language)
  const { i18n } = useTranslation()

  const changeLanguage = (event) => {
    i18n.changeLanguage(event)
    console.log(event)
    localStorage.setItem('language', event)
  }

  const items = data.map((item) => (
    <Menu.Item
      icon={
        <Image
          src={item.image}
          width={30}
          height={20}
          alt={`Bandeira do ${item.country}`}
        />
      }
      onClick={() => {
        setSelected(item)
        localStorage.setItem('languageSelected', JSON.stringify(item))
        changeLanguage(item.language.replace(/"/g, ''))
        setLanguage(item.language.replace(/"/g, ''))
      }}
      style={!dark ? undefined : { color: 'white' }}
      key={item.title}
    >
      <option
        value={item.language}
        style={{ fontSize: '1.2rem' }}
        onClick={() => {
          changeLanguage(item.language.replace(/"/g, ''))
          setLanguage(item.language.replace(/"/g, ''))
        }}
      >
        {item.title}
      </option>
    </Menu.Item>
  ))

  // useEffect(() => {
  //   if (language) {
  //     changeLanguage(language)
  //   }
  // }, [language])

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    const language = localStorage.getItem('languageSelected')
    const languageSelected = JSON.parse(language)
    if (savedLanguage) {
      changeLanguage(savedLanguage)
    }
    if (languageSelected) {
      setSelected(languageSelected)
    }
  }, [])

  const { t } = useTranslation()

  return (
    <Styled.Container dark={dark}>
      <Styled.Navbar>
        <Link to="/">
        <img src={darkEasyTransferLogo} id="logo" alt="Logotipo EasyTransfer" />
        </Link>
        
        <Header />

        <div
          style={{
            zIndex: '9999999999999',
            display: 'flex',
            alignItems: 'center',
            gap: '25px'
          }}
        >
          {checkout && (
            <>
              <Styled.HelpTitle>
                Help <img src={helpIcon} />
              </Styled.HelpTitle>

              <Styled.MoneyDropdown>
                € <img src={iconChevronDown} />
              </Styled.MoneyDropdown>
            </>
          )}
          <Menu
            onOpen={() => setOpened(true)}
            onClose={() => setOpened(false)}
            radius="md"
            width="target"
            style={
              !dark
                ? undefined
                : {
                    backgroundColor: 'black',
                    color: 'white',
                    border: '1px solid #1f1e1e'
                  }
            }
            withinPortal
          >
            <Menu.Target>
              <UnstyledButton className={classes.control}>
                <Group spacing="xs">
                  <Image
                    src={selected.image}
                    width={40}
                    height={30}
                    alt={`Bandeira do ${selected.country}`}
                  />
                  <span className={classes.label}>{selected.label}</span>
                </Group>
                <img src={iconChevronDown} alt="Flecha de seleção" />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown
              className={!dark ? classes.dropdown : classes.darkDropdown}
            >
              {items}
            </Menu.Dropdown>
          </Menu>
        </div>
        <Styled.NavbarWrapper>
          <SidebarToggle />
        </Styled.NavbarWrapper>
      </Styled.Navbar>
    </Styled.Container>
  )
}
