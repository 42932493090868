import { useEffect, useMemo, useState } from 'react'

import { useTour } from '../../useTour'

import { Rating, Tooltip } from '@mantine/core'

import {
  ButtonOrdering,
  CardMostChosen,
  CardMostChosenBody,
  CardMostChosenImage,
  CardMostChosenRating,
  CardMostChosenSeeMore,
  CardMostChosenTime,
  CardMostChosenTitle,
  ContainerCards,
  ContainerInput,
  Form,
  HeaderMaisEscolhidos,
  Input,
  LeftIconInput,
  RightIconInput,
  Title
} from './styles'

import { ArrowDownUp, MapPin, Search } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import fetchTour from '../../tours'
import { ClipLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import { formatCurrency } from '../../../../utils/formatCurrency'

export default function MostChosen() {
  const { selected } = useTour()

  const [searchTerm, setSearchTerm] = useState('')
  const [sortOrder, setSortOrder] = useState('desc')
  const [loadingTours, setLoadingTours] = useState(true)

  const [tours, setTours] = useState([])
  const language = localStorage.getItem('language')

  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    setLoadingTours(true)
    try {
      fetchTour.getTours(language).then((res) => {
        setTours(res.data.data)
        setLoadingTours(false)
      })
    } catch (err) {
      setLoadingTours(false)
    }
  }, [language])

  const filteredAndSortedTours = useMemo(() => {
    if (!tours) {
      return []
    }

    // Filtrar pelos melhores tours
    const bestTours = tours.filter((tour) => tour.attributes.bestTour === true)

    // Aplicar filtro de busca
    const filteredTours = bestTours.filter((tour) =>
      tour.attributes.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

    // Ordenar
    const sortedTours = filteredTours.slice().sort((a, b) => {
      const orderFactor = sortOrder === 'asc' ? 1 : -1
      return (
        orderFactor * (a.attributes.providerPrice - b.attributes.providerPrice)
      )
    })

    return sortedTours
  }, [selected, searchTerm, sortOrder, tours])

  const handleSortOrderChange = () => {
    if (sortOrder === 'desc') {
      setSortOrder('asc')

      return
    }

    setSortOrder('desc')
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const goToTour = (tour) => {
    navigate(`/tour/${tour.attributes.name}`, {
      state: tour
    })
    console.log(tour, 'TOUR!!')
  }

  return (

    <section style={{ paddingBottom: '100px' }}>

      <HeaderMaisEscolhidos>
        <Title>{t('tours.mostChosen')}</Title>

        <ContainerInput>
          <Form>
            <LeftIconInput>
              <MapPin size={16} color="#27c7ff" />
            </LeftIconInput>
            <Input
              placeholder={t('tours.searchDestinationTour')}
              onChange={handleSearchChange}
            />
            <RightIconInput>
              <Search size={16} color="#27c7ff" />
            </RightIconInput>
          </Form>

          <Tooltip label={t('orderByPrice')} position="bottom" color='dark' transitionProps={{ transition: "scale-y", duration: 300 }}
            sx={{
              backgroundColor: '#ffffff',
              boxShadow: '0px 5.03px 56.57px 0px rgba(0, 0, 0, 0.2)',
              padding: '10px',
              borderRadius: '8px',

              fontWeight: 400,
              fontSize: '14px',
              whiteSpace: 'nowrap',
              pointerEvents: 'none',
              cursor: 'default',
              color: '#222222',
              opacity: 0,
            }}>
            <ButtonOrdering onClick={handleSortOrderChange}>
              <ArrowDownUp color="#27c7ff" />
            </ButtonOrdering>
          </Tooltip>

        </ContainerInput>

        {loadingTours && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
              width: '100%'
            }}
          >
            <ClipLoader loading={loadingTours} size={50} color="#27c7ff" />
          </div>
        )}
        <ContainerCards>
          {!loadingTours &&
            filteredAndSortedTours?.map((item, index) => (
              <CardMostChosen key={index} onClick={() => goToTour(item)}>
                <CardMostChosenImage
                  image={item?.attributes?.mainImage?.data?.attributes?.url}
                />
                <CardMostChosenBody>
                  <div>
                    <CardMostChosenTitle>
                      {item?.attributes.name}
                    </CardMostChosenTitle>
                    <CardMostChosenRating>
                      <Rating
                        value={item?.attributes.rate}
                        fractions={2}
                        readOnly
                        size="xl"
                      />
                      <p>{item.attributes.rate}</p>
                    </CardMostChosenRating>
                  </div>
                  <CardMostChosenTime>
                    <h5>{t('tours.duration')}</h5>
                    <p>
                      {item?.attributes.durationInHours} {t('tours.hours')}
                    </p>
                  </CardMostChosenTime>
                  <CardMostChosenSeeMore>
                    <p>
                      €
                      {formatCurrency(item?.attributes.providerPrice).replace(
                        '€',
                        ''
                      )}
                      <span>/ {t('tours.tour')}</span>
                    </p>

                    <button>{t('seeMore')}</button>
                  </CardMostChosenSeeMore>
                </CardMostChosenBody>
              </CardMostChosen>
            ))}
        </ContainerCards>
      </HeaderMaisEscolhidos>


    </section>
  )
}
