import styled, { css } from 'styled-components'

import * as SidebarToggleStyles from './components/SidebarToggle/styles'

export const Navbar = styled.ul`
  display: flex;
  align-items: center;
  gap: 4rem;
  z-index: 99;
  width: 100%;

  a:first-of-type {
    margin-right: auto;
  }

  .mantine-UnstyledButton-root {
    width: 100%;
    height: 40px;
  }

  .mantine-Menu-dropdown {
    display: none;
    z-index: 99999999 !important;
  }

  #logo {
    height: 3rem;
    width: fit-content;
  }

  @media (max-width: 768px) {
    div,
    li,
    button:not(.navToggle),
    a:not(:first-of-type) {
      display: none;
    }
    a:first-of-type {
      margin-right: 0;
    }
    justify-content: space-between;
    #logo {
      height: 3rem;
    }
  }
`

export const Link = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray};
    list-style: none;
    font-size: 1.4rem;
    line-height: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.4rem;

    svg {
      color: #808080;
    }
  `}

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #27c7ff;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }
`

export const Button = styled.button`
  padding: 0 1.6rem;
  height: 4rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(39, 199, 255, 0.3);
  background: #27c7ff;
  border: 0;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  font-size: 1.4rem;
  transition: 1s;
  border: solid 1px #27c7ff;
  line-height: 1;

  svg {
    color: #ffffff;
    transition: 1s;
  }

  &:hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #27c7ff;

    svg {
      color: #27c7ff;
    }
  }
`
export const NavbarWrapper = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const containerModifiers = {
  dark: () => css`
    background-color: #000000;

    ${Button} {
      color: #ffffff;
      background-color: #FBB03B;
      border: solid 1px #FBB03B;

      &:hover {
        background-color: #000;
        color: #FBB03B;
        border: solid 1px #FBB03B;
      }
    }

    ${SidebarToggleStyles.Wrapper} {
      span {
        background-color: #ffffff;
      }
    }
  `,

  transparent: () => css`
    background-color: transparent;
    background-image: linear-gradient(
      to bottom,
      rgb(255, 255, 255) 40%,
      transparent 100%
    );

    box-shadow: none;

    /* ${Button} {
      color: #ffffff;
    } */

    /* ${Link} {
      color: #ffffff !important;

      svg {
        color: #ffffff;
      }
    } */

    /* ${SidebarToggleStyles.Wrapper} {
      span {
        background-color: #ffffff;
      }
    } */
  `
}

export const HelpTitle = styled.h4`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #0d0d0d;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const MoneyDropdown = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Container = styled.header`
  ${({ dark, transparent }) => css`
    font-weight: '400';
    padding: 3rem;

    display: flex;
    justify-content: center;
    height: 8rem;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px 18.0092px 27.2828px rgba(0, 0, 0, 0.07);
    transition: background-color 0.3s ease-in-out;

    ${dark && containerModifiers.dark}
    ${transparent && containerModifiers.transparent}

    @media only screen and (max-width: 768px) {
      ${HelpTitle}, ${MoneyDropdown} {
        display: none;
    }
  `}
`

export const ExpandableContainer = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
`

const expandableItemsWrapperModifiers = {
  dark: () => css`
    div {
      background-color: #1b1b1b;

      ul li {
        color: #ffffff;

        :hover {
          background-color: #171100;
        }
      }
    }
  `
}

export const ExpandableItemsWrapper = styled.div`
  ${({ dark }) => css`
    position: absolute;
    padding-top: 2rem;
    right: -2.8rem;
    div {
      padding: 1.6rem 0;
      border-radius: 0.8rem;
      background-color: #ffff;
      box-shadow: 0px 31.010395050048828px 33.82952117919922px 0px
        rgba(0, 0, 0, 0.07);
      width: 18rem;

      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        li {
          text-align: center;
          font-family: Neometric;
          font-weight: 400;
          font-size: 1.4rem;
          color: #222222;
          padding: 0.8rem 0;
          transition: background-color 0.2s ease-in-out,
            font-weight 0.2s ease-in-out;

          :hover {
            font-weight: 500;
            background-color: #DFF7FF;
          }
        }
      }
    }

    ${dark && expandableItemsWrapperModifiers.dark}
  `}
`
