import styled from 'styled-components'

import { Button as UIButton } from '../../../../components/Button'
import { Badge as UIBadge } from '../../../../components/Badge'
import { Separator as UISeparator } from '../../../../components/Separator'

import bannerImg from '../../imgs/banner-motorista.webp'

export const Wrapper = styled.section`
  margin: 0 auto;
  display: flex;
  width: 100%;
  height: 100vh;
  /* max-width: 144rem; */
  flex-direction: column;
  justify-content: center;
  background-size: cover;
  background-image: url(${bannerImg});

  > div {
    margin: 0 auto;
    display: flex;
    width: 100%;
    max-width: 140.8rem;
    flex-direction: column;
    padding: 8rem 4rem;

    @media (min-width: 1024px) {
      padding-left: 16rem;
      padding-right: 16rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      width: 40rem;

      h1 {
        font-size: 5.6rem;
        font-weight: bold;
        color: #FBB03B;
        line-height: 1;
      }

      p {
        font-size: 1.8rem;

        span {
          font-size: 2.4rem; /* 24px */
          line-height: 3.2rem; /* 32px */
          font-weight: bold;
          color: #FBB03B;
        }
      }

      > div:last-of-type {
        display: flex;
        gap: 1.6rem;
        width: 100%;
      }
    }
  }
`
export const Button = styled(UIButton)`
  background-color: #FBB03B;
  border-radius: 1.2rem;
  padding: 1.6rem 3.2rem;
  font-weight: bold;
  color: #27272a;
  font-size: 1.6rem;
  height: 5.6rem;

  :hover {
    background-color: #FBB03B;
    opacity: 0.9;
  }
`

export const Badge = styled(UIBadge)`
  flex: 1;
  width: 100%;
  display: flex;
  gap: 1rem;
  border-radius: 0.8rem;
  background-color: #27272a;
  color: #FBB03B;
  padding: clamp(8px, 0.42vw, 0.42vw);

  svg {
    /* width: 2.8rem; */
    /* height: 2.8rem; */
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    span {
      /* font-size: 1.2rem; 12px */
      /* line-height: 1.6rem; 16px */
      font: 400 1.1rem Roboto, sans-serif !important;
      letter-spacing: 0.5px;
    }
  }
`

export const Separator = styled(UISeparator)`
  height: 100%;
  width: 0.1rem;
  background-color: #FBB03B;
`
