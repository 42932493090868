import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 208.92px;
  height: 49.83px;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const Text = styled.p`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: normal;
  width: 100%;
  height: 18px;
  /* identical to box height */

  color: #808080;

  cursor: pointer;
`

export const Label = styled.label`
  font-family: 'Neometric';
  font-style: normal;

  color: #404040;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: normal;
`

export const IconContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 2%;
`

export const Icon = styled.img``

export const CounterIcon = styled.img``

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  .mantine-Menu-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.6rem;
    /* min-width: 34.6rem !important; */
    width: 32rem !important;
    left: 1.4rem !important;
    gap: 8px;

    > div,
    > div > div {
      width: 100%;
    }

    .mantine-MonthLevelGroup-monthLevelGroup {
      margin: 0 auto;
    }

    .mantine-CalendarHeader-calendarHeader {
      max-width: none;
    }

    .mantine-Month-month,
    .mantine-YearLevel-yearLevel,
    .mantine-MonthsList-monthsList,
    .mantine-DecadeLevel-decadeLevel,
    .mantine-YearsList-yearsList {
      width: 100%;
    }

    .mantine-CalendarHeader-calendarHeaderControl svg {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      min-height: 2rem;
    }

    .mantine-CalendarHeader-calendarHeaderLevel {
      font-size: 2rem !important;
    }

    /* .mantine-WeekdaysRow-weekday {
      font-size: 1.2rem !important;
    }

    .mantine-Day-day {
      font-size: 1.6rem !important;
    } */

    .mantine-Day-day[data-weekend='true'] {
      color: #000 !important;

      &[data-selected='true'] {
        color: #fff !important;
      }
    }

    .mantine-Month-month {
      td {
        width: 3.6rem;
        height: 3.6rem;
        text-align: center;
        border-radius: 1.2rem;
        padding: 0;
        position: relative;
        font-size: 1.4rem;
        line-height: 2rem;

        button {
          width: 3.6rem;
          height: 3.6rem;
          font-size: inherit;
          padding: 0;
          font-weight: 400;
          background-color: transparent;

          :hover {
            background-color: hsl(210 40% 96.1%);
            color: hsl(222.2 47.4% 11.2%);
          }

          &[data-selected='true'] {
            background-color: #27c7ff;
          }
        }
      }
    }

    .time-toggle {
      width: 100%;
      display: flex;
      border-radius: 0.8rem;
      overflow: hidden;
      margin-bottom: 1.6rem;

      button {
        max-width: 50%;
        width: 100%;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.9rem;
        background-color: #f7f7f7;

        color: #808080;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 16.8px */
        letter-spacing: 0.14px;
        text-align: left;

        svg {
          stroke: #808080;
          width: 2.4rem;
          min-width: 2.4rem;
          height: 2.4rem;
          min-height: 2.4rem;
          margin-right: 0.8rem;
        }

        &.active {
          background-color: #27c7ff;
          color: #222222;

          svg {
            stroke: #222222;
          }
        }
      }
    }

    .mantine-TimeInput-icon img {
      width: 24px;
      height: 24px;
    }

    .mantine-TimeInput-input {
      border: 0.507906px solid #bfbfbf;
      border-radius: 6.09488px;
    }

    .mantine-TimeInput {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #0d0d0d;
    }

    .mantine-TimeInput-wrapper,
    .mantine-TimeInput-input {
      width: 100%;
      height: 54px;

      border: 0.507906px solid #bfbfbf;
      border-radius: 6.09488px;
    }

    .mantine-TimeInput-input {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #0d0d0d;
    }
    .mantine-TimeInput-icon {
      margin-left: 1rem;
    }

    .mantine-TimeInput-input[data-with-icon] {
      padding-left: 4.5rem;
    }

    span {
      display: flex;
      align-items: center;
      color: #404040;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 2rem;
      width: 100%;
      background-color: #E9F9FF;
      border-radius: 0.8rem;
      padding: 0.8rem 1.6rem;

      svg {
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        min-height: 2rem;
        margin-right: 0.8rem;
      }
    }
  }

  && input {
        padding-left: 1rem;
  }
`

export const IconX = styled.img`
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px;
  cursor: pointer;
`

export const CounterContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .btn-plus {
    width: 16px !important;
    height: 16px !important;

    background: rgba(68, 255, 109, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    font-size: 22px;
  }

  .btn-minus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;

    width: 16px;
    height: 16px;

    background: rgba(216, 99, 99, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
  }

  strong {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #38280e;
    width: 7px;
    height: 17px;
  }
`

export const CounterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-style: normal;
    font-weight: 700;
    font-size: 8px;
    line-height: 9px;

    color: #38280e;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 9px;

    color: #38280e;
  }
`

export const CounterLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  width: 50%;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  gap: 10px;
  justify-content: flex-end;
`
