import { useEffect, useState } from 'react'
import { DatePicker } from '@mantine/dates'
import * as Styled from './styles'

import { ReactComponent as ChevronRight } from '../../../../assets/chevron-right.svg'
import Pencil from './imgs/lucide_pencil- blue branding.svg'
import Briefcase from './imgs/icon_briefcase.svg'
import Passenger from './imgs/lucide_users-2- blue branding.svg'
import Shape from './imgs/icon_surfboard- blue branding.svg'
import Carseat from './imgs/icon_car_baby_seat- blue branding.svg'
import Paw from './imgs/icon_pet- blue branding.svg'

import { Popover } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { OutPortal } from 'react-reverse-portal'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Checked } from '../Checked'
import { HourSelect } from '../../../../components/ReserveComponent/components/HourSelect'

const SHARE_RIDE_DISCOUNT = 0.25
const REFOUNT_TAX = 4.97
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3

export const OrderSummary = ({ portalNode, reservation = false }) => {
  const { t } = useTranslation()
  const { event, setEvent, returnTravel, setReturnTravel } =
    useTransferContext()

  const [, setEditing] = useState(false)
  const [hasReturnNightTax, setHasReturnNightTax] = useState(false)
  const expandableMatch = useMediaQuery('(max-width: 944px)')

  const hasGoingNightTax =
    dayjs(event.dateTime).get('hours') > 19 ||
    (dayjs(event.dateTime).get('hours') >= 19 &&
      dayjs(event.dateTime).get('minutes') >= 30) ||
    dayjs(event.dateTime).get('hours') < 8 ||
    (dayjs(event.dateTime).get('hours') == 8 &&
      dayjs(event.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const goingAnimalsTax = event.animals
    ? event.animalsQuantity * ANIMALS_TAX
    : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const goingChildSeatsTax = event.childseat
    ? (event.childSeat + event.boosterSeat + event.babyChair) * CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const goingSurfboardsTax = event.surf
    ? event.surfQuantity * SURFBOARDS_TAX
    : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0

  const [selectedTimeGoing, setSelectedTimeGoing] = useState(
    event.dateTime
      ? new Date(event.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : ''
  )

  const [selectedDateReturn, setSelectedDateReturn] = useState(
    returnTravel.active && !!returnTravel.dateTime
      ? returnTravel.dateTime
      : new Date(dayjs(event.dateTime).add(1, 'hours'))
  )

  const [selectedTimeReturn, setSelectedTimeReturn] = useState(
    returnTravel.active && returnTravel.dateTime
      ? new Date(returnTravel.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : `${String(dayjs(event.dateTime).add(1, 'hours').get('hours')).padStart(
          2,
          '0'
        )}:${String(
          dayjs(event.dateTime).add(1, 'hours').get('minutes')
        ).padStart(2, '0')}`
  )

  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [isExpandedOrderSummary, setIsExpandedOrderSummary] = useState(false)
  const contentRef = useRef(null)
  const containerRef = useRef(null)

  const handleEditClick = () => {
    setEditing(true)
  }

  const getAbbreviatedWeekday = (date) => {
    const weekday = date.toLocaleString('default', { weekday: 'short' })
    return weekday.substring(0, 3).toUpperCase()
  }

  useEffect(() => {
    if (event.totalPrice) {
      const taxCalculate = event.totalPrice * 0.06
      setTax(taxCalculate)
      setShareRideDiscount(0)
    }
  }, [event.totalPrice])

  useEffect(() => {
    if (selectedTimeGoing) {
      console.log({ event, iso: event.dateTime.toISOString() })
      console.log('new date', {
        date: new Date(
          dayjs(event.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })

      setEvent({
        ...event,
        dateTime: new Date(
          dayjs(event.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })
    }
  }, [selectedTimeGoing])

  useEffect(() => {
    if (!!selectedDateReturn && returnTravel.active) {
      const dateTime = new Date(
        dayjs(selectedDateReturn).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
          `T${selectedTimeReturn}:00`
      )

      setReturnTravel({
        ...returnTravel,
        dateTime
      })

      setHasReturnNightTax(
        returnTravel.active &&
          (dayjs(dateTime).get('hours') > 19 ||
            (dayjs(dateTime).get('hours') >= 19 &&
              dayjs(dateTime).get('minutes') >= 30) ||
            dayjs(dateTime).get('hours') < 8 ||
            (dayjs(dateTime).get('hours') == 8 &&
              dayjs(dateTime).get('minutes') == 0))
      )
    }
  }, [selectedDateReturn, selectedTimeReturn, returnTravel.active])

  const handleToggleExpandSummary = () => {
    setIsExpandedOrderSummary((oldState) => !oldState)
  }

  useEffect(() => {
    setEvent({
      ...event,
      totalPrice: event.pricePerPerson * event.allPassangers
    })
  }, [event.allPassangers])

  return (
    <Styled.Container
      expanded={(expandableMatch && isExpandedOrderSummary) || reservation}
      ref={containerRef}
    >
      {reservation && <Checked />}
      {expandableMatch && !reservation && (
        <button onClick={handleToggleExpandSummary}>
          <ChevronRight />
          {isExpandedOrderSummary ? 'MINIMIZAR' : 'MOSTRAR DETALHES'}
        </button>
      )}
      <h2>{t('orderSummary.title')}</h2>
      <div>
        <div
          className="content-wrapper"
          ref={contentRef}
          style={
            !reservation && !isExpandedOrderSummary && expandableMatch
              ? { display: 'none' }
              : undefined
          }
        >
          {event && (
            <>
              <Styled.FormRow style={{ marginTop: '2.4rem' }}>
                <Styled.StrongSpan>
                  {t('orderSummary.orderNumberLabel')}:
                </Styled.StrongSpan>
                <Styled.NormalSpan>034938429</Styled.NormalSpan>
              </Styled.FormRow>
              <OutPortal node={portalNode} />
              {event.name && (
                <Styled.ContentWrapper>
                  <Styled.RouteWrapper>
                    <Styled.Thumbnail
                      src={event?.mainImage?.data?.attributes?.url}
                    />
                    <Styled.RouteStrongSpan>
                      {event.name}
                    </Styled.RouteStrongSpan>
                    <Styled.HeaderWrapper>
                      <Popover position="bottom" shadow="md">
                        <Popover.Dropdown>
                          <Styled.CalendarWrapper>
                            <DatePicker
                              value={event.dateTime}
                              onChange={(value) => {
                                if (
                                  dayjs(value.toISOString()).get('date') ===
                                    dayjs().add(8, 'hours').get('date') &&
                                  dayjs(value.toISOString()).get('month') ===
                                    dayjs().add(8, 'hours').get('month') &&
                                  dayjs(value.toISOString()).get('year') ===
                                    dayjs().add(8, 'hours').get('year')
                                ) {
                                  const hour = String(
                                    dayjs().add(8, 'hours').get('hours')
                                  ).padStart(2, '0')
                                  const minute = String(
                                    dayjs().add(8, 'hours').get('minutes')
                                  ).padStart(2, '0')

                                  setSelectedTimeGoing(`${hour}:${minute}`)
                                }

                                if (
                                  selectedDateReturn &&
                                  dayjs(
                                    selectedDateReturn.toISOString()
                                  ).isBefore(dayjs(value.toISOString()))
                                ) {
                                  setSelectedDateReturn(
                                    new Date(dayjs(value).add(1, 'hour'))
                                  )

                                  if (selectedTimeGoing) {
                                    if (selectedTimeGoing.startsWith('23')) {
                                      setSelectedDateReturn(
                                        new Date(dayjs(value).add(1, 'days'))
                                      )
                                    }

                                    const hours = Number(
                                      selectedTimeGoing.split(':')[0]
                                    )
                                    const minutes = Number(
                                      selectedTimeGoing.split(':')[1]
                                    )

                                    setSelectedTimeReturn(
                                      `${String(
                                        hours < 23 ? hours + 1 : 0
                                      ).padStart(2, '0')}:${String(
                                        minutes < 59 ? minutes : 0
                                      ).padStart(2, '0')}`
                                    )
                                  }
                                }

                                setEvent({ ...event, dateTime: value })
                              }}
                              minDate={new Date(event.date)}
                              maxDate={new Date(event.date)}
                            />
                            <Styled.SelectHourContainer>
                              <HourSelect
                                value={selectedTimeGoing}
                                onChange={(e) => setSelectedTimeGoing(e)}
                              />
                            </Styled.SelectHourContainer>
                          </Styled.CalendarWrapper>
                        </Popover.Dropdown>
                        <Styled.DateAndHour>
                          {getAbbreviatedWeekday(event.dateTime)},{' '}
                          {event.dateTime.toLocaleDateString()} -{' '}
                          {selectedTimeGoing}
                        </Styled.DateAndHour>
                        {!reservation && (
                          <Popover.Target>
                            <Styled.EditContainer>
                              <span onClick={handleEditClick}>
                                {t('orderSummary.editLabel')}
                              </span>
                              <img src={Pencil} alt="Edit icon" />
                            </Styled.EditContainer>
                          </Popover.Target>
                        )}
                      </Popover>
                    </Styled.HeaderWrapper>
                    <Styled.IconsAndNumbersWrapper>
                      {(event.checkedLuggage > 0 ||
                        event.backPack > 0 ||
                        event.handLuggage > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Briefcase}
                            style={{ height: '24px' }}
                            alt="Briefcase icon"
                          />
                          <span>
                            {event.checkedLuggage +
                              event.backPack +
                              event.handLuggage}
                          </span>
                        </Styled.IconAndNumber>
                      )}
                      {event.allPassangers > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Passenger}
                            style={{ height: '24px' }}
                            alt="Passenger icon"
                          />
                          <span>{event.allPassangers}</span>
                        </Styled.IconAndNumber>
                      )}
                      {event.surfQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Shape}
                            style={{ height: '24px' }}
                            alt="Shape icon"
                          />
                          <span>{event.surfQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                      {(event.babyChair > 0 ||
                        event.boosterSeat > 0 ||
                        event.childSeat > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Carseat}
                            style={{ height: '24px' }}
                            alt="Carseat icon"
                          />
                          <span>
                            {event.babyChair +
                              event.boosterSeat +
                              event.childSeat}
                          </span>
                        </Styled.IconAndNumber>
                      )}

                      {event.animalsQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Paw}
                            style={{ height: '24px' }}
                            alt="Paw icon"
                          />
                          <span>{event.animalsQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                    </Styled.IconsAndNumbersWrapper>
                  </Styled.RouteWrapper>
                </Styled.ContentWrapper>
              )}

              <Styled.Divider />
            </>
          )}
        </div>
        {event.totalPrice > 0 && (
          <>
            <div
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { display: 'none' }
                  : undefined
              }
            >
              <Styled.BillWrapper>
                <h2>{t('orderSummary.billTitle')}</h2>
                <Styled.BillRow>
                  <span className="title">
                    {t('orderSummary.itemDescription')}
                  </span>
                  <span className="title">{t('orderSummary.price')}</span>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.transferItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    € {formatCurrency(event.totalPrice).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.tax6Item')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    € {formatCurrency(tax).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                {(goingAnimalsTax > 0 || returnAnimalsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Animais
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingAnimalsTax + returnAnimalsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(goingSurfboardsTax > 0 || returnSurfboardsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Pranchas de Surf
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingSurfboardsTax + returnSurfboardsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(goingChildSeatsTax > 0 || returnChildSeatsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Child Seats
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingChildSeatsTax + returnChildSeatsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(hasGoingNightTax || hasReturnNightTax) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      {t('orderSummary.nightTax')}
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      € {formatCurrency(nightTax).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {event.discountAmount > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Desconto</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      - €{' '}
                      {formatCurrency(event.discountAmount).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {/* <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.refundTaxItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {event.reimbursement
                      ? formatCurrency(REFOUNT_TAX).replace('€', '')
                      : formatCurrency(0).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow> */}
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.total')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {formatCurrency(
                      event.totalPrice -
                        event.discountAmount +
                        nightTax +
                        goingAnimalsTax +
                        goingSurfboardsTax +
                        goingChildSeatsTax +
                        (event.reimbursement ? REFOUNT_TAX : 0) +
                        tax
                    ).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
              </Styled.BillWrapper>
              <Styled.Divider />
            </div>
            <Styled.Footer
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { marginTop: '-4.4rem' }
                  : undefined
              }
            >
              <h5>
                €{' '}
                {formatCurrency(
                  event.totalPrice -
                    event.discountAmount +
                    nightTax +
                    goingAnimalsTax +
                    goingSurfboardsTax +
                    goingChildSeatsTax +
                    (event.reimbursement ? REFOUNT_TAX : 0) +
                    tax
                ).replace('€', '')}
              </h5>
              <div>
                <p>{t('checkoutTour.Total price')}</p>
                <span>{t('checkoutTour.Taxes & fees included')}</span>
              </div>
            </Styled.Footer>
          </>
        )}
      </div>
      {reservation && <Link to="/">VOLTAR PARA HOME</Link>}
    </Styled.Container>
  )
}
