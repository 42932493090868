import { useEffect } from 'react'
import * as Styled from './styles'
import fetchBooking from '../../../../services/booking'
import fetchAccount from '../../../../services/account'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input, Pagination } from '@mantine/core'
import { ChevronDown, Search } from 'lucide-react'
import debounce from '../../../../utils/debounce'
import { RejectModal } from './components/RejectModal'
import { toast } from 'react-toastify'
import { DataTableFinancial } from '../../../../components/DataTableFinancial'
import { SwitchOptions } from './components/SwitchOptions'
import { Counters } from './components/Counters'
import { TableFilters } from '../../../../components/TableFilters'
import { filtersDriver, filtersOverview } from './filters'
import { ClipLoader } from 'react-spinners'
import { CSVLink } from 'react-csv'
import { FinancialPartners } from '../../../../components/FinancialPartners'
import { useTranslation } from 'react-i18next'

const financialValues = [
  {
    option: 'Visão Geral',
    first: true,
    type: 'geral'
  },
  {
    option: 'Motoristas',
    type: 'drivers'
  },
  {
    option: 'Partnership',
    type: 'partnership'
  },
  {
    option: 'Tours',
    type: 'tours'
  },
  {
    option: 'Eventos',
    type: 'events'
  },
  {
    option: 'Low Cost',
    type: 'lowCost',
    last: true
  }
]

export const Financial = () => {
  const [data, setData] = useState()
  const [total, setTotal] = useState()
  const [search, setSearch] = useState({
    // name: '',
    // orderBy: 'name',
    size: 8,
    page: 0
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [refreshModal, setRefreshModal] = useState(false)
  const [selection, setSelection] = useState(['1'])
  const [option, setOption] = useState({
    option: 'Visão Geral',
    type: 'geral',
    first: true
  })
  const [filterModal, setFilterModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleChange = (value, name) => {
    if (name === 'size' || name === 'page') {
      setSearch((prevSearch) => ({ ...prevSearch, [name]: value }))
    } else {
      debounce(() => setSearch({ ...search, [name]: value }), 500)
    }
  }

  const fetchFunctionMap = {
    drivers: fetchBooking.getAllBooking?.bind(fetchBooking),
    partnership: fetchBooking.getAllBooking?.bind(fetchBooking),
    tours: fetchBooking.getAllBooking?.bind(fetchBooking),
    events: fetchBooking.getAllBooking?.bind(fetchBooking),
    geral: fetchBooking.getAllBooking?.bind(fetchBooking),
    lowCost: fetchBooking.getAllBooking?.bind(fetchBooking)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const params = Object.entries(search).reduce(
          (acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
              acc[key] = value
            }
            return acc
          },
          {
            status: 'DONE'
          }
        )

        const fetchFunction = fetchFunctionMap[option.type]

        const result = await fetchFunction(params)

        if (result) {
          setData(
            option.type === 'drivers' || option.type === 'partnership'
              ? result.data.content
              : result.data.content
          )

          const totalPages = Math.ceil(result.data.totalElements / search.size)
          setTotal(totalPages)
        }
      } catch (err) {
        console.error(err, 'err')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [search, refreshModal, option])

  const handleDeleteAccount = () => {
    fetchBooking
      .delete(deleteModal.id)
      .then(() => {
        toast.success(t('toast.successDeleteAccount'))
        setDeleteModal(false)
        setRefreshModal(!refreshModal)
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(t('toast.errorDeleteAccount'))
      })
  }

  const typeFilters = {
    geral: filtersOverview,
    drivers: filtersDriver
  }

  const filters = typeFilters[option.type] || filtersOverview

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.Title>Financeiro</Styled.Title>
        <CSVLink
          data={data ? data : []}
          separator=";"
          filename="financial_report.csv"
        >
          <Styled.ConfirmButton>Exportar</Styled.ConfirmButton>
        </CSVLink>
      </Styled.Header>
      <Counters type={option.type} />

      <Styled.FiltersWrapper>
        <SwitchOptions
          options={financialValues}
          setValue={(newOption) => {
            setSearch((prevFilters) => {
              const newFilters = { ...prevFilters }
              if (prevFilters?.hostelId) delete newFilters.hostelId
              return newFilters
            })
            setOption(newOption)
          }}
          value={option}
        />
        <div style={{ display: 'flex', gap: '8px' }}>
          <Input.Wrapper id="filters">
            <Input
              id="input-demo"
              placeholder="Insira o nome"
              size={'lg'}
              rightSection={<Search width={24} height={24} color="#BFBFBF" />}
              onChange={(e) => handleChange(e.target.value, 'name')}
            />
          </Input.Wrapper>
          <Input
            id="select-filter"
            component="input"
            rightSection={<ChevronDown />}
            pointer
            mt="md"
            onClick={() => setFilterModal(true)}
            value="Filtros"
            placeholder="Filtros"
          />
        </div>

        <TableFilters
          isActive={filterModal}
          filters={filters}
          onClose={() => setFilterModal(false)}
          setSelectFilters={setSearch}
          selectFilters={search}
        />
      </Styled.FiltersWrapper>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '40rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.Content>
          {option.type === 'partnership' && (
            <FinancialPartners setFilters={setSearch} filters={search} />
          )}
          {(option.type !== 'partnership' ||
            (option.type === 'partnership' && search?.hostelId)) && (
            <>
              <DataTableFinancial
                data={data}
                setDelete={setDeleteModal}
                selection={selection}
                setSelection={setSelection}
                type={option.type}
              />
              <Pagination
                className="pagination"
                size="xl"
                total={total}
                radius="md"
                color="27C7FF" 
                withControls={false}
                value={search?.page + 1}
                onChange={(value) => handleChange(value - 1, 'page')}
              />
            </>
          )}
        </Styled.Content>
      )}

      <RejectModal
        opened={deleteModal}
        onClose={() => setDeleteModal(false)}
        onSuccess={handleDeleteAccount}
      />
    </Styled.Container>
  )
}
