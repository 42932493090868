import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Title = styled.h1`
  text-align: center;
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 100%;

  color: #222222;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 100px;
`


export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  max-width: 1168px;
  text-align: center;
  /* @media (max-width: 500px) {
    padding: 0 1.6rem;
   } */
  
`

export const CategoryTextContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 2.4rem;
  margin-bottom: 2.4rem;
  margin-top: 2.4rem;
  @media (max-width: 500px) {
    margin: 2.4rem 1.6rem;
    padding: 0 1.6rem;
  }
`

export const CategoryText = styled.p`
  font-size: 2.5rem;
  line-height: 0;
  font-family: 'Neometric';
  font-weight: bolder;

  ${({ theme }) => css`
     color: ${theme.colors.primary};
  `}
`

export const CategoryLine = styled.div`
  width: 100%;
  height: 2px;
  background-color: #DFF7FF;
`

export const CarouselImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  @media (max-width:768px) {
    height: unset;
  
  }
`;

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;
    background: linear-gradient(180deg, #FFFFFF 50%, rgba(255, 255, 255, 0.5) 70%, rgba(153, 153, 153, 0) 88.54%);

    @media (max-width: 768px) {
    height: unset;
    }
  }

  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 90px;
    background: linear-gradient(0deg, #FFFFFF 50%, rgba(255, 255, 255, 0.5) 70%, rgba(153, 153, 153, 0) 88.54%);
    
    @media (max-width: 768px) {
    height: 20px;
    }
  }
`

export const CarouselImg = styled.img`
  width: 100%;
  height: 400px;
  object-fit: scale-down;

  @media (max-width: 768px) {
    /* object-position: 50% 100%; */
    object-fit: scale-down;
    height: auto;
  }

  border-radius: 4px;
`;


export const CarouselContainer = styled.div`
  max-width: 1168px;
  margin-top: 8rem;  

  @media (max-width: 768px) {
    width: 100%;
  }
`