import styled from 'styled-components'

import MostChosenImage from '../../../../assets/image2@2x.png'

export const HeaderMaisEscolhidos = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 905px) {
    align-items: flex-start;
    justify-content: flex-start;

    padding: 0;
  }
`
export const Title = styled.h3`
  position: relative;

  font-family: 'Neometric';
  font-size: 32px;
  line-height: 32px;
  font-weight: 600;

  margin: 0;
`

export const ContainerInput = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 905px) {
    width: 100%;

    justify-content: space-between;
  }
`

export const Form = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 12px;

  @media screen and (max-width: 905px) {
    width: 100%;
  }
`

export const Input = styled.input`
  width: 550px;
  height: 48px;

  color: #a2a2a2;

  padding: 4px 32px;

  border: 1px solid #bfbfbf;
  border-radius: 8px;

  &::placeholder {
    color: #a2a2a2;
  }

  @media screen and (max-width: 905px) {
    width: 100%;
  }
`

export const LeftIconInput = styled.div`
  position: absolute;
  left: 14px;
`

export const RightIconInput = styled.div`
  position: absolute;
  right: 14px;
`
export const ButtonOrdering = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  width: 52px;
  min-width: 52px;
  min-height: 52px;
  height: 52px;

  border-radius: 16px;
  border: none;
  background-color: #f7f7f7;

  :hover {
    ::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  
`

export const ContainerCards = styled.div`
  /* display: flex; */
  /* flex-flow: row wrap; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  gap: 10px;

  margin-top: 24px;

  @media screen and (max-width: 768px) {
    gap: 2.4rem;
  }

  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
  }
`

export const CardMostChosen = styled.div`
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: 38rem;
  height: 44rem;

  border-radius: 8px;

  padding-bottom: 24px;

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  @media screen and (max-width: 620px) {
    max-width: none;
  }

  @media screen and (max-width: 905px) {
    width: 100%;
  }
`
export const CardMostChosenImage = styled.image`
  width: 100%;
  height: 52rem;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  object-fit: cover;
`

export const CardMostChosenBody = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding: 14px 14px 0px 14px;

  box-sizing: border-box;
`

export const CardMostChosenTitle = styled.p`
  font-family: Neometric;
  font-weight: 600;
  font-size: 16px;
  color: #222222;

  -webkit-line-clamp: 2 !important;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  line-clamp: 2;
`

export const CardMostChosenRating = styled.div`
  display: flex;
  align-items: center;

  margin-top: 12px;

  p {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;

    color: #222222;

    margin-left: 8px;
  }
`

export const CardMostChosenTime = styled.div`
  display: flex;
  gap: 4px;

  margin-top: 12px;

  h5,
  p {
    font-family: 'Poppins' !important;
    font-size: 1.2rem;
    color: #222222;
  }
`

export const CardMostChosenSeeMore = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  max-width: calc(100% - 28px);

  margin-top: 12px;

  p {
    font-family: Neometric;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
  }

  span {
    font-family: Neometric;
    font-weight: 400;
    font-size: 14px;
    color: #222222;
  }

  button {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;

    height: 40px;

    padding: 12px;

    border-radius: 14px;
    background-color: #27c7ff;
  }
`
