import { useEffect, useState } from 'react'
import { Input, LoadingOverlay, Pagination, Tabs } from '@mantine/core'
import { Search } from 'lucide-react'
import { CategoriesConfig } from './components/CategoriesConfig'
import { FleetConfig } from './components/FleetConfig'
import { PartnershipConfig } from './components/PatnershipConfig'
import debounce from '../../../../utils/debounce'
import fetchConfig from '../../../../services/config'
import fetchCarCategory from '../../../../services/carCategory'
import * as Styled from './styles'
import { toast } from 'react-toastify'
import { CreateCategoryModal } from './components/CreateCategoryModal'
import { ClipLoader } from 'react-spinners'
import { generateEditableData } from './utils'
import { CompanyConfig } from './components/CompanyConfig'
import { useTranslation } from 'react-i18next'

const INITIAL_CONFIG_STATE = {
  defaultMaxDailyTransfers: 0,
  defaultDriverCommission: 0,
  defaultHostelCommission: 0
}

const PAGE_SIZE = 50

export const Config = () => {
  const [search, setSearch] = useState('')
  const [activeTab, setActiveTab] = useState('categories')
  const [createCategoryModal, setCreateCategoryModal] = useState(false)
  const [config, setConfig] = useState(INITIAL_CONFIG_STATE)
  const [categories, setCategories] = useState([])
  const [categoryToEdit, setCategoryToEdit] = useState(undefined)
  const [currentPage, setCurrentPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [loadingState, setLoadingState] = useState({
    loadingConfig: true,
    loadingCategories: false,
    deletingCategory: false,
    updatingConfig: false
  })

  const { t } = useTranslation()

  const handleChange = (value) => {
    debounce(() => setSearch(value), 500)
  }

  const handleChangeConfig = (field, value) => {
    setConfig((oldState) => ({ ...oldState, [field]: value }))
  }

  const openCreateCategoryModal = () => {
    setCreateCategoryModal(true)
  }

  const configUpdate = config.id ? fetchConfig.update : fetchConfig.create

  const saveConfig = async () => {
    setLoadingState((oldState) => ({ ...oldState, updatingConfig: true }))
    try {
      await configUpdate(config)
      toast.success(t('toast.successSaveConfiguration'))
      setLoadingState((oldState) => ({ ...oldState, updatingConfig: false }))
    } catch (err) {
      console.log(err)
      toast.error(t('toast.errorSaveCofigurations'))
    }
  }

  const onSubmitCategory = (categoryData) => {
    const categoryIndex = categories.findIndex(
      (cat) => cat.id === categoryData.id
    )
    if (categoryIndex > -1) {
      const newCategories = [...categories]
      newCategories.splice(categoryIndex, 1, categoryData)
      setCategories(newCategories)
    } else {
      setCategories((oldState) => [...oldState, categoryData])
    }
    setCreateCategoryModal(false)
    setCategoryToEdit(undefined)
  }

  const editCategory = (category) => {
    setCategoryToEdit(category)
    setCreateCategoryModal(true)
  }

  const deleteCategory = async (id) => {
    const toastId = toast.loading('Removendo categoria...')
    setLoadingState((oldState) => ({ ...oldState, deletingCategory: true }))
    try {
      await fetchCarCategory.delete(id)
      const newCategories = categories.filter((category) => category.id !== id)
      setCategories(newCategories)
      setLoadingState((oldState) => ({ ...oldState, deletingCategory: false }))
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: `Categoria removida com sucesso!`
      })
    } catch (err) {
      console.log(err)
      setLoadingState((oldState) => ({ ...oldState, deletingCategory: false }))
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: `Erro ao remover categoria!`
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoadingState((oldState) => ({ ...oldState, loadingConfig: true }))
      try {
        const response = await fetchConfig.get()
        setConfig(response.data)
        setLoadingState((oldState) => ({ ...oldState, loadingConfig: false }))
      } catch (err) {
        console.log(err)
        setConfig(INITIAL_CONFIG_STATE)
        setLoadingState((oldState) => ({ ...oldState, loadingConfig: false }))
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoadingState((oldState) => ({ ...oldState, loadingCategories: true }))

      const params = {
        size: PAGE_SIZE,
        // coupon: search || undefined,
        name: search || undefined,
        page: currentPage,
        deleted: false
      }
      try {
        const response = await fetchCarCategory.getAll(params)
        const total = Math.ceil(response.data.totalElements / PAGE_SIZE)
        setTotalPages(total)
        setCategories(response.data.content || [])
        setLoadingState((oldState) => ({
          ...oldState,
          loadingCategories: false
        }))
      } catch (err) {
        console.log(err)
        setCategories([])
        setLoadingState((oldState) => ({
          ...oldState,
          loadingCategories: false
        }))

        setCurrentPage(0)
        setTotalPages(1)
      }
    })()
  }, [search, currentPage])

  return (
    <>
      <Styled.Container>
        <LoadingOverlay
          overlayOpacity={0.5}
          visible={loadingState.loadingConfig}
        />
        <Styled.Header>
          <Styled.Title>Configurações</Styled.Title>
          <Styled.ConfirmButton
            onClick={
              activeTab === 'categories' ? openCreateCategoryModal : saveConfig
            }
            disabled={
              (activeTab !== 'categories' && loadingState.updatingConfig) ||
              loadingState.deletingCategory
            }
          >
            {activeTab !== 'categories' ? 'Salvar' : 'NOVA'}
          </Styled.ConfirmButton>
        </Styled.Header>
        <Styled.FiltersWrapper
          style={
            activeTab !== 'categories'
              ? { visibility: 'hidden', pointerEvents: 'none' }
              : undefined
          }
        >
          <Input.Wrapper id="filters">
            <Input
              id="input-demo"
              placeholder="Pesquisar"
              size={'lg'}
              rightSection={<Search width={24} height={24} color="#BFBFBF" />}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Input.Wrapper>
        </Styled.FiltersWrapper>
        <Styled.Content>
          <Tabs
            color="orange-primary"
            value={activeTab}
            onTabChange={setActiveTab}
          >
            <Tabs.List>
              <Tabs.Tab value="categories">Categorias</Tabs.Tab>
              <Tabs.Tab value="fleet">Frota</Tabs.Tab>
              <Tabs.Tab value="partnership">Partnership</Tabs.Tab>
              <Tabs.Tab value="company">Company</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="categories">
              {loadingState.loadingCategories ? (
                <div
                  style={{
                    width: '100%',
                    height: '40rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <ClipLoader size={50} color="#27c7ff" />
                </div>
              ) : (
                <>
                  <CategoriesConfig
                    categories={categories}
                    deleteCategory={deleteCategory}
                    editCategory={editCategory}
                  />
                  {totalPages > 1 && (
                    <Pagination
                      className="pagination"
                      size="xl"
                      total={totalPages}
                      radius="md"
                      color="27C7FF" 
                      withControls={false}
                      value={currentPage + 1}
                      onChange={(value) => setCurrentPage(value - 1)}
                    />
                  )}
                </>
              )}
            </Tabs.Panel>
            <Tabs.Panel value="fleet">
              <FleetConfig
                config={config}
                handleChangeConfig={handleChangeConfig}
              />
            </Tabs.Panel>
            <Tabs.Panel value="partnership">
              <PartnershipConfig
                config={config}
                handleChangeConfig={handleChangeConfig}
              />
            </Tabs.Panel>
            <Tabs.Panel value="company">
              <CompanyConfig
                config={config}
                handleChangeConfig={handleChangeConfig}
              />
            </Tabs.Panel>
          </Tabs>
        </Styled.Content>
      </Styled.Container>
      {createCategoryModal && (
        <CreateCategoryModal
          initialData={
            categoryToEdit ? generateEditableData(categoryToEdit) : undefined
          }
          onSuccess={onSubmitCategory}
          onClose={() => {
            setCreateCategoryModal(false)
            setCategoryToEdit(undefined)
          }}
        />
      )}
    </>
  )
}
