/* eslint-disable no-unused-vars */
import { ActionIcon, Checkbox, Group, Input, NumberInput } from '@mantine/core'
import { Carousel } from '@mantine/carousel'
import * as Styled from './styles'
import { useState, useRef, useEffect } from 'react'

import Send from './imgs/lucide_map-pin-grey 3.svg'
// import Xis from './imgs/xis.webp'
import Airplane from './imgs/airplane.webp'
import Search from './imgs/Search.webp'
import MinusBtn from './imgs/icon_minus_blue.webp'
import PlusBtn from './imgs/icon_plus_blue.webp'
import BriefCase from './imgs/icon_briefcase.svg'
import Luggage from './imgs/icon_luggage.svg'
import Backpack from './imgs/icon_backpack.svg'
import Childseat1 from './imgs/childseat1.webp'
import Childseat2 from './imgs/childseat2.webp'
import Childseat3 from './imgs/childseat3.webp'
import aveiroImg from './imgs/aveiro.png'
import ericeiraImg from './imgs/ericeira.png'
// import MinusSmall from './imgs/minus-small.webp'
// import PlusSmall from './imgs/plus-small.webp'

import { AddPassenger } from './components/AddPassenger'
import { NavigationTabs } from '../NavigationTabs'
import { PopoverButton } from '../PopoverButton'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

import { Autocomplete } from '@react-google-maps/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { StopSelection } from '../StopSelection'

const passengerStops = [
  {
    title: 'Aveiro',
    time: '5 min stop',
    price: '$ 40',
    img: aveiroImg
  },
  {
    title: 'Ericeira',
    time: '5 min stop',
    price: '$ 40',
    img: ericeiraImg
  },
  {
    title: 'Aveiro',
    time: '5 min stop',
    price: '$ 40',
    img: aveiroImg
  },
  {
    title: 'Ericeira',
    time: '5 min stop',
    price: '$ 40',
    img: ericeiraImg
  }
]

export const Transfer = () => {
  const [iAgree, setIAgree] = useState(true)
  const handlers = useRef()
  const [, setCurrentTab] = useState('first')

  /**@type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /**@type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  /**@type React.MutableRefObject<HTMLInputElement> */
  const returnOriginRef = useRef()
  /**@type React.MutableRefObject<HTMLInputElement> */
  const returnDestinationRef = useRef()

  const responsiveSurfAndAnimals = useMediaQuery('(max-width: 944px)')

  const { event, setEvent, carSelected, isLoaded } = useTransferContext()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const continueTransfer = () => {
    if (event.name === '' || event.allPassangers === 0) {
      return toast.error(t('toast.fulfilAllInformations'))
    }

    if (!iAgree) {
      return toast.error(t('toast.termsAndConditionsRule'))
    }

    navigate('/checkout2/event')
  }

  const IncreaseValue = (value, name, context, setContext) => {
    setContext({ ...context, [name]: value + 1 })
  }

  const DecreaseValue = (value, name, context, setContext) => {
    if (value < 1) {
      return
    } else {
      setContext({ ...context, [name]: value - 1 })
    }
  }

  useEffect(() => {
    if (carSelected) {
      if (event.allPassangers > carSelected.passengers) {
        setEvent({ ...event, allPassangers: carSelected.passengers })
      }
    }
  }, [event, carSelected])

  const firstPanel = (
    <>
      <Input.Wrapper
        id="input-demo"
        label={
          'Nome da Tour'
          // t('transfer.from')
        }
        style={{ marginTop: '20px' }}
      >
        <Input
          required
          icon={<img src={Send} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="Nome da Tour"
          size={'lg'}
          value={event.name}
        />
      </Input.Wrapper>

      <Styled.NumberContainer>
        <label>{t('transfer.passengers')}</label>
        <Group
          spacing={5}
          style={{ justifyContent: 'space-between', position: 'relative' }}
        >
          <ActionIcon
            className="btn-minus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() =>
              DecreaseValue(
                event.allPassangers,
                'allPassangers',
                event,
                setEvent
              )
            }
          >
            <img src={MinusBtn} />
          </ActionIcon>

          <NumberInput
            hideControls
            value={event.allPassangers}
            // onChange={(val) => setValue(val)}
            handlersRef={handlers}
            max={10}
            min={0}
            step={1}
            styles={{ input: { width: '100%', textAlign: 'center' } }}
            style={{ width: '100%' }}
          />

          {event.allPassangers < event?.maxNPassengers && (
            <ActionIcon
              className="btn-plus"
              size={60}
              variant="transparent"
              style={{
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none'
              }}
              onClick={() =>
                IncreaseValue(
                  event.allPassangers,
                  'allPassangers',
                  event,
                  setEvent
                )
              }
            >
              <img src={PlusBtn} />
            </ActionIcon>
          )}
        </Group>
      </Styled.NumberContainer>
      <Styled.OptionsContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.backpack')}
            setValue={setEvent}
            value={event.backPack}
            disabledIncreaseAction={
              event.checkedLuggage + event.handLuggage + event.backPack ===
              carSelected?.briefcase
            }
            context={event}
            name="backPack"
            icon={Backpack}
            width="100%"
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.handLuggage')}
            setValue={setEvent}
            value={event.handLuggage}
            disabledIncreaseAction={
              event.checkedLuggage + event.handLuggage + event.backPack ===
              carSelected?.briefcase
            }
            context={event}
            name="handLuggage"
            icon={Luggage}
            width="100%"
          />
        </Styled.NumberContainer>

        <Styled.NumberContainer>
          <AddPassenger
            responsive
            label={t('transfer.checkedLuggage')}
            setValue={setEvent}
            value={event.checkedLuggage}
            disabledIncreaseAction={
              event.checkedLuggage + event.handLuggage + event.backPack ===
              carSelected?.briefcase
            }
            context={event}
            name="checkedLuggage"
            icon={BriefCase}
            width="100%"
          />
        </Styled.NumberContainer>
      </Styled.OptionsContainer>

      <Styled.CheckboxOptionsContainer>
        <Checkbox
          size="lg"
          checked={event.childseat}
          onChange={() => setEvent({ ...event, childseat: !event.childseat })}
          label={t('transfer.childseat')}
        />
        {event.childseat && (
          <Styled.ChildCardsWrapper>
            <Styled.ChildCardContainer>
              <Checkbox default />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat1} />
                <Styled.ChildCardText>
                  <h1>{t('checkoutTour.Cadeira de bebê')}</h1>
                  <span>{t('checkoutTour.0 MONTHS - 12 MONTHS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            event.babyChair,
                            'babyChair',
                            event,
                            setEvent
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={event.babyChair}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            event.babyChair,
                            'babyChair',
                            event,
                            setEvent
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat2} />
                <Styled.ChildCardText>
                  <h1>{t('transfer.childseat')}</h1>
                  <span>{t('checkoutTour.1 YEAR - 4 YEARS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            event.childSeat,
                            'childSeat',
                            event,
                            setEvent
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={event.childSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            event.childSeat,
                            'childSeat',
                            event,
                            setEvent
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
            <Styled.ChildCardContainer>
              <Checkbox />
              <Styled.ChildCard>
                <Styled.ChildCardImg src={Childseat3} />
                <Styled.ChildCardText>
                  <h1>{t('checkoutTour.Banco elevatório')}</h1>
                  <span>{t('checkoutTour.4 YEARS - 12 YEARS')}</span>
                  <Styled.NumberSmallContainer>
                    <Group
                      spacing={5}
                      style={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'relative'
                      }}
                    >
                      <ActionIcon
                        size={11}
                        variant="default"
                        onClick={() =>
                          DecreaseValue(
                            event.boosterSeat,
                            'boosterSeat',
                            event,
                            setEvent
                          )
                        }
                        className="btn-minus"
                      >
                        -
                      </ActionIcon>

                      <NumberInput
                        hideControls
                        value={event.boosterSeat}
                        max={10}
                        min={0}
                        step={1}
                        styles={{
                          input: { width: '100%', textAlign: 'center' }
                        }}
                        style={{ width: '100%' }}
                      />

                      <ActionIcon
                        size={11}
                        className="btn-plus"
                        variant="default"
                        onClick={() =>
                          IncreaseValue(
                            event.boosterSeat,
                            'boosterSeat',
                            event,
                            setEvent
                          )
                        }
                      >
                        +
                      </ActionIcon>
                    </Group>
                  </Styled.NumberSmallContainer>
                </Styled.ChildCardText>
              </Styled.ChildCard>
            </Styled.ChildCardContainer>
          </Styled.ChildCardsWrapper>
        )}
      </Styled.CheckboxOptionsContainer>
      <Styled.SurfAndAnimalsWrapper>
        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            checked={event.surf}
            onChange={() => setEvent({ ...event, surf: !event.surf })}
            label={t('transfer.surfboard')}
          />
          {event.surf && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                setValue={setEvent}
                value={event.surfQuantity}
                context={event}
                name="surfQuantity"
                width="149.3px"
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>

        <Styled.CheckboxOptionsContainer>
          <Checkbox
            size="lg"
            checked={event.animals}
            onChange={() => setEvent({ ...event, animals: !event.animals })}
            label={t('transfer.animals')}
          />
          {event.animals && (
            <Styled.NumberContainer>
              <AddPassenger
                responsive={responsiveSurfAndAnimals}
                setValue={setEvent}
                value={event.animalsQuantity}
                name="animalsQuantity"
                width="149.3px"
                context={event}
              />
            </Styled.NumberContainer>
          )}
        </Styled.CheckboxOptionsContainer>
      </Styled.SurfAndAnimalsWrapper>

      <Styled.CheckedItemsContainer>
        <Styled.CheckedItemWrapper>
          <Checkbox
            size="lg"
            onChange={() => setIAgree(!iAgree)}
            label={t('transfer.iAgree')}
            checked={iAgree}
          />
        </Styled.CheckedItemWrapper>
      </Styled.CheckedItemsContainer>
    </>
  )

  if (!isLoaded) {
    return <div>teste</div>
  }

  return (
    <Styled.Container>
      <h1>Transfer</h1>
      {firstPanel}
      <Styled.ButtonContainer>
        {/* <Styled.JumpButton>{t('transfer.jumpButton')}</Styled.JumpButton> */}
        <Styled.SaveButton onClick={continueTransfer}>
          {t('transfer.continueButton')}
        </Styled.SaveButton>
      </Styled.ButtonContainer>
    </Styled.Container>
  )
}
