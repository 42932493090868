import { useJsApiLoader } from '@react-google-maps/api'
import { createContext, useState, useContext, useEffect } from 'react'
import { toast } from 'react-toastify'

import { useTranslation } from 'react-i18next'
import useAPI from '../hooks/useAPI'
import directionService from '../services/directionService'
import { libraries } from './api/libraries'

export const GOING_INITIAL_STATE = {
  from: '',
  to: '',
  flightNumber: '',
  dateTime: '',
  allPassangers: 0,
  passangerAdult: 0,
  passangerChild: 0,
  passangerInfant: 0,
  handLuggage: 0,
  checkedLuggage: 0,
  backPack: 0,
  surfQuantity: 0,
  surf: false,
  childseat: false,
  babyChair: 0,
  childSeat: 0,
  boosterSeat: 0,
  animals: false,
  animalsQuantity: 0,
  shareRide: false,
  passengerName: '',
  passengerPhoneNumber: '',
  passengerEmail: '',
  reimbursement: false,
  distance: '',
  duration: '',
  paymentMethod: 'Card',
  totalPrice: 0,
  discountAmount: 0,
  bookingStops: [],
  passengerStops: []
}

export const RETURN_INITIAL_STATE = {
  active: true,
  from: '',
  to: '',
  flightNumber: '',
  dateTime: '',
  allPassangers: 0,
  passangerAdult: 0,
  passangerChild: 0,
  passangerInfant: 0,
  handLuggage: 0,
  checkedLuggage: 0,
  backPack: 0,
  surfQuantity: 0,
  surf: false,
  childseat: false,
  babyChair: 0,
  childSeat: 0,
  boosterSeat: 0,
  distance: '',
  duration: '',
  animals: false,
  animalsQuantity: 0,
  // shareRide: false,
  passengerName: '',
  passengerPhoneNumber: '',
  passengerEmail: '',
  totalPrice: 0,
  discountAmount: 0
}

const TransferContext = createContext()

export const useTransferContext = () => {
  return useContext(TransferContext)
}

const TransferProvider = ({ children }) => {
  const api = useAPI()
  const [going, setGoing] = useState(GOING_INITIAL_STATE)
  const [lowCost, setLowCost] = useState(GOING_INITIAL_STATE)
  const [tour, setTour] = useState(GOING_INITIAL_STATE)
  const [event, setEvent] = useState(GOING_INITIAL_STATE)

  const [returnTravel, setReturnTravel] = useState(RETURN_INITIAL_STATE)

  const [arrival, setArrival] = useState('')
  const [dateTime, setDateTime] = useState('')
  const [allPassangers, setAllPassangers] = useState(0)
  const [passangerAdult, setPassangerAdult] = useState(0)
  const [passangerChildren, setPassangerChildren] = useState(0)
  const [passangerInfants, setPassangerInfants] = useState(0)
  const [directionsResponse, setDirectionsResponse] = useState()

  const [carSelected, setCarSelected] = useState()
  const [carItem, setCarItem] = useState()
  const [carPagination, setCarPagination] = useState({
    totalElements: 0,
    totalPages: 0,
    page: 0
  })

  const { t } = useTranslation()

  const clearTour = () => {
    setTour(GOING_INITIAL_STATE)
  }

  const clearEvent = () => {
    setEvent(GOING_INITIAL_STATE)
  }

  useEffect(() => {
    setGoing({
      ...going,
      allPassangers:
        going.passangerAdult + going.passangerChild + going.passangerInfant
    })
  }, [going.passangerAdult, going.passangerChild, going.passangerInfant])
  useEffect(() => {
    setTour({
      ...tour,
      allPassangers:
        tour.passangerAdult + tour.passangerChild + tour.passangerInfant
    })
  }, [tour.passangerAdult, tour.passangerChild, tour.passangerInfant])

  useEffect(() => {
    setReturnTravel({
      ...returnTravel,
      allPassangers:
        returnTravel.passangerAdult +
        returnTravel.passangerChild +
        returnTravel.passangerInfant
    })
  }, [
    returnTravel.passangerAdult,
    returnTravel.passangerChild,
    returnTravel.passangerInfant
  ])

  let googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  if (!googleMapsApiKey) {
    googleMapsApiKey = 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg'
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: libraries
  })

  const calculateRoute = async (
    originRef,
    destinationRef,
    passengerStops = undefined,
    type = 'going'
  ) => {
    if (
      originRef?.current?.value !== '' &&
      destinationRef?.current?.value !== ''
    ) {
      try {
        const results = await api.easyTransfer.api.directionClient.getRouteDetails({
          origin: originRef?.current?.value,
          destination: destinationRef?.current?.value,
          travelMode: 'DRIVING',
          passengerStops
        })

        console.log(
          originRef.current,
          destinationRef.current,
          results,
          results?.routes[0]?.legs[0]?.start_location?.lat(),
          `CONTEXT TRANSFER UOU ${type}`
        )

        setDirectionsResponse((oldState) => ({
          ...oldState,
          [type]: results
        }))

        if (type === 'going') {
          setGoing({
            ...going,
            from: originRef?.current?.value,
            to: destinationRef?.current?.value,
            distance: directionService.extractDistanceFromLegsToKm(results),
            duration: directionService.extractDurationFromLegs(results),
            distanceMeters: results?.routes[0]?.legs.reduce(
              (acc, curr) => (acc += curr.distance?.value),
              0
            ),
            startingLatitude:
              results?.routes[0]?.legs[0]?.start_location?.lat(),
            startingLongitude:
              results?.routes[0]?.legs[0]?.start_location?.lng(),
            passengerStops: passengerStops
              ? going.passengerStops.map((stop, idx) => ({
                ...stop,
                place: stop.from,
                latitude: results?.routes[0]?.legs[idx]?.end_location?.lat(),
                longitude: results?.routes[0]?.legs[idx]?.end_location?.lng()
              }))
              : going.passengerStops,
            arrivalLatitude:
              results?.routes[0]?.legs[
                results?.routes[0]?.legs.length - 1
              ]?.end_location?.lat(),
            arrivalLongitude:
              results?.routes[0]?.legs[
                results?.routes[0]?.legs.length - 1
              ]?.end_location?.lng(),
            // durationInSeconds: results?.routes[0]?.legs[0]?.duration?.value
            durationInSeconds: results?.routes[0]?.legs.reduce(
              (acc, curr) => (acc += curr.duration?.value),
              0
            )
          })
        } else {
          setReturnTravel({
            ...returnTravel,
            from: originRef?.current?.value,
            to: destinationRef?.current?.value,
            // distance: results?.routes[0]?.legs[0]?.distance?.text,
            distance: directionService.extractDistanceFromLegsToKm(results),
            // duration: results?.routes[0]?.legs[0]?.duration?.text,
            duration: directionService.extractDurationFromLegs(results),
            distanceMeters: results?.routes[0]?.legs.reduce(
              (acc, curr) => (acc += curr.distance?.value),
              0
            ),
            startingLatitude:
              results?.routes[0]?.legs[0]?.start_location?.lat(),
            startingLongitude:
              results?.routes[0]?.legs[0]?.start_location?.lng(),
            passengerStops: passengerStops
              ? going.passengerStops.map((stop, idx) => ({
                ...stop,
                latitude: results?.routes[0]?.legs[idx]?.end_location?.lat(),
                longitude: results?.routes[0]?.legs[idx]?.end_location?.lng()
              }))
              : going.passengerStops,
            arrivalLatitude:
              results?.routes[0]?.legs[
                results?.routes[0]?.legs.length - 1
              ]?.end_location?.lat(),
            arrivalLongitude:
              results?.routes[0]?.legs[
                results?.routes[0]?.legs.length - 1
              ]?.end_location?.lng(),
            // durationInSeconds: results?.routes[0]?.legs[0]?.duration?.value
            durationInSeconds: results?.routes[0]?.legs.reduce(
              (acc, curr) => (acc += curr.duration?.value),
              0
            )
          })
        }
      } catch (err) {
        if (err.code === 'NOT_FOUND') {
          toast.error(t('toast.validAddress'))
        }
      }
    }
  }

  const calculateRouteWithoutState = async (origin, destination) => {
    if (!origin || !destination) return
    try {
      //eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService()
      //eslint-disable-next-line no-undef
      const originLatLng = new google.maps.LatLng(
        origin.latitude,
        origin.longitude
      )
      //eslint-disable-next-line no-undef
      const destinationLatLng = new google.maps.LatLng(
        destination.latitude,
        destination.longitude
      )
      const results = await directionsService.route({
        origin: originLatLng,
        destination: destinationLatLng,
        //eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING
      })
      return results
    } catch (err) {
      console.log({ err })
      return undefined
    }
  }

  const autocompleteSearch = async (search) => {
    const apiURL = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${search}&key=AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg`
    //eslint-disable-next-line no-undef
    const service = new google.maps.places.AutocompleteService()
    const result = await service.getPlacePredictions({
      input: search
    })
    return result
  }

  return (
    <TransferContext.Provider
      value={{
        going,
        setGoing,
        tour,
        setTour,
        event,
        setEvent,
        returnTravel,
        setReturnTravel,
        clearTour,
        clearEvent,
        arrival,
        setArrival,
        dateTime,
        setDateTime,
        allPassangers,
        setAllPassangers,
        passangerAdult,
        setPassangerAdult,
        passangerChildren,
        setPassangerChildren,
        passangerInfants,
        setPassangerInfants,
        carSelected,
        setCarSelected,
        carItem,
        setCarItem,
        directionsResponse,
        setDirectionsResponse,
        calculateRouteWithoutState,
        calculateRoute,
        isLoaded,
        autocompleteSearch,
        carPagination,
        setCarPagination,
        lowCost,
        setLowCost
        // saveData,
        // clearData
      }}
    >
      {children}
    </TransferContext.Provider>
  )
}

export default TransferProvider
