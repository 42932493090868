import * as Styled from './styles'
import { DateTimePicker } from './components/DateTimePicker'
import { Passengers } from './components/Passengers'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import fetchEvents from '../../../../services/eventStrapi'
import { SearchField } from './components/SearchField'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { MapPin, Users2, CalendarDays } from 'lucide-react'
import { formatCurrency } from '../../../../utils/formatCurrency'

export const Eventos = () => {
  const {
    event,
    setEvent,
    setReturnTravel,
    returnTravel,
    // calculateRoute,
    isLoaded
  } = useTransferContext()

  const mobileMatch = useMediaQuery('(max-width: 634px)')
  const [toursOptions, setToursOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [firstEvent, setFirstEvent] = useState(true)
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [goingDate, setGoingDate] = useState(() => {
    if (!event.dateTime) return undefined
    return new Date(
      `${event.dateTime
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-')}T00:00:00`
    )
  })
  const [goingTime, setGoingTime] = useState(() => {
    if (!event.dateTime) return '00:00'
    return `${String(event.dateTime.getHours()).padStart(2, '0')}:${String(
      event.dateTime.getMinutes()
    ).padStart(2, '0')}`
  })

  useEffect(() => {
    setLoading(true)
    const params = {
      populate: 'mainImage',
      size: 999
    }
    fetchEvents.getAll(params).then((res) => {
      setLoading(false)
      const toursMap = res.data.data.map((event) => {
        return {
          value: event.id,
          label: event.attributes.name,
          ...event.attributes
        }
      })
      if (!event.name) {
        setEvent({
          ...event,
          ...toursMap[0]
        })
      }
      setToursOptions(toursMap)
    })
  }, [])

  const handleReserv = () => {
    if (
      event.arrivalLocation === '' ||
      event.departureLocation === '' ||
      event.allPassangers === 0 ||
      !goingDate
    ) {
      return toast.error(t('toast.fulfilAllInformations'))
    }

    if (event.allPassangers < event.minNPassengers) {
      toast.error(`
        ${t('toast.passangersQuantityError')} ${event.minNPassengers}
        `)
      return
    }
    const minDate = dayjs().add(8, 'hours')

    const fullDate = `${goingDate.toISOString().split('T')[0]}T${goingTime}:00`

    if (dayjs(fullDate).isBefore(dayjs())) {
      toast.error(t('toast.transferDateRangeError'))

      return
    }

    if (dayjs(fullDate).isBefore(minDate)) {
      toast.error(t('toast.transferHourRangeError'))

      return
    }

    setEvent({ ...event, dateTime: new Date(fullDate) })

    navigate('/checkout/event')
  }
  console.log(event, 'TOUR')
  useEffect(() => {
    setEvent({
      ...event,
      totalPrice: event.pricePerPerson * event.allPassangers
    })
  }, [event.allPassangers])

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '20rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.TransferContainer>
          <Styled.LeftContainer>
            {!event?.name ? (
              <strong
                style={{
                  font: '600 1.4rem Poppins, sans-serif',
                  display: 'block',
                  margin: 'auto'
                }}
              >
                Selecione um evento
              </strong>
            ) : (
              <img
                src={
                  mobileMatch
                    ? event?.mainImage?.data?.attributes?.url
                    : event?.mainImage?.data?.attributes?.url
                }
              />
            )}
            {/* {event?.name && mobileMatch && <h3>{event?.name}</h3>} */}
          </Styled.LeftContainer>
          <Styled.OneTransferContainer>
            <Styled.OneTransfer style={{ zIndex: 2 }}>
              <SearchField
                icon={
                  <MapPin
                    size="2.4rem"
                    color="#27c7ff"
                    style={{ alignSelf: 'flex-end', marginBottom: '4%' }}
                  />
                }
                label={t('event')}
                placeholder={(t('selectEvent'))}
                onChange={(value) => {
                  setEvent({ ...event, ...value })
                  setFirstEvent(false)
                }}
                options={toursOptions}

              // setIsAirport={setIsAirport}
              />
            </Styled.OneTransfer>

            <Styled.OneTransfer>
              <DateTimePicker
                setPassengers={setEvent}
                passengers={event}
                img={<CalendarDays size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de calendário'}
                date={goingDate}
                time={goingTime}
                setDate={setGoingDate}
                setTime={setGoingTime}
                maxDate={event?.date}
              // isAirport={isAirport}
              />
            </Styled.OneTransfer>
            <Styled.OneTransfer style={{ zIndex: 1 }}>
              <Passengers
                setPassengers={setEvent}
                passengers={event}
                img={<Users2 size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de passageiros'}
                maxValue={event?.maxNPassengers}
              />
            </Styled.OneTransfer>

            <p className="warn">
              {t('maxLimit')} {event?.maxNPassengers} {t('people')}
            </p>
          </Styled.OneTransferContainer>

          <Styled.BtnContainer>
            {!firstEvent && (
              <>
                <div>
                  <p>{event?.name}</p>
                  <strong>
                    {!event?.name
                      ? 0
                      : event.allPassangers <= 1
                        ? formatCurrency(event.pricePerPerson).replace('€', '')
                        : formatCurrency(event.totalPrice).replace('€', '')}
                    €
                  </strong>
                </div>
                <Styled.BtnPrimary disabled={!event?.name} onClick={handleReserv}>
                  {t('reserveComponent.reserve')}
                </Styled.BtnPrimary>
              </>
            )}

          </Styled.BtnContainer>
        </Styled.TransferContainer>
      )}
    </>
  )
}
