import APIClient from "../APIClient"
import DirectionClient from "../external/directionClient"

class EasyTransferClient {

    constructor() {
        this.api = {
            directionClient: new DirectionClient()
        }
    }

    createAPIClient(baseURL, headers) {
        return new APIClient(baseURL, headers)
    }

    clear() {
        this.api.directionClient
    }
}

export default EasyTransferClient